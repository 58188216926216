import { createSlice } from '@reduxjs/toolkit';

const myDepositSlice = createSlice({
    name: 'myDeposit',
    initialState: {
        deposits: {}, 
        loading: false,
        error: null,
    },
    reducers: {
        setDeposits(state, action) { 
            state.deposits = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    },
});

export const { setDeposits, setLoading, setError } = myDepositSlice.actions;
export default myDepositSlice.reducer;
