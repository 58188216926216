import React, { useState, useMemo, memo,useEffect } from 'react'
import Select from 'react-select'
import { Country } from 'country-state-city';

function CountrySelector({ setCountry , placeholder = 'Country'}) {
    const [options,setOptions]=useState([]);

    useEffect(()=>{
        const countries=Country.getAllCountries();
        const countryOptions=countries.map(country=>({
           value: country.isoCode,
            label: country.name,
        }))
        setOptions(countryOptions);
    },[])

    const changeHandler = (selectedCountry) => {
        setCountry(selectedCountry.value); 
    };


    return <Select 
    options={options} 
    onChange={changeHandler} 
    className='outline-none w-full'
    inputId='select-box-input'
    placeholder={placeholder}
    />
}

export default memo(CountrySelector)