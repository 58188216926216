import FileInput from 'components/global/FileInput';
import Input from 'components/global/Input';
import NameInput from 'components/global/NameInput'
import Textarea from 'components/global/Textarea';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { ticket_createDoc } from 'redux/actions/ticketActions';
import { setShowCreateTicketPopup } from 'redux/reducers/ticketReducer';

const CreateTicketPopup = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const { createLoading } = useSelector(state => state.ticket);

    const [image , setImage] = useState('');
    const [ticketData , setTicketData] = useState({
        subject : '' ,
        comment : ''
    });

    const submitHandler = (e) => {
        e.preventDefault();
        const data = {
            ...ticketData ,
            name : user?.username ,
            user : user?._id 
        }
        if(image) {
            data.image = image;
        }
        dispatch(ticket_createDoc(data))
    }

    return (
        <div className='bg-black bg-opacity-50 fixed top-0 left-0 w-full min-h-screen flex items-center justify-center px-3'>
            <div className='bg-pure border border-gray-200 sm:px-5 px-2 rounded-lg h-[450px] md:w-[500px] w-full overflow-auto pb-6 text-sm'>
                <div 
                className='flex items-center justify-between gap-4 border-b py-4'
                >
                    <h3 className='font-semibold text-white'>
                        For Any Issue Please Create Ticket.
                    </h3>
                    <button 
                    className='text-2xl font-bold cursor-pointer'
                    onClick={() => dispatch(setShowCreateTicketPopup(false))}
                    >
                        <i className="uil uil-times text-white"></i>
                    </button>
                </div>
                <form 
                className='flex flex-col gap-4 mt-4'
                onSubmit={submitHandler}
                >
                    <NameInput
                    label='Subject'
                    placeholder='Enter subject'
                    data={ticketData}
                    setData={setTicketData}
                    name='subject'
                    required
                    />

                    <Textarea
                    label='Comment'
                    placeholder='Please explain your issue'
                    data={ticketData}
                    setData={setTicketData}
                    name='comment'
                    required
                    />
                    <FileInput
                    label='Image'
                    value={image}
                    setValue={setImage}
                    required
                    />

                    <div className='mt-4 flex items-center gap-4'>
                        <button 
                        className="btn-white py-2 px-4 text-white"
                        type='button'
                        disabled={createLoading}
                        onClick={() => dispatch(setShowCreateTicketPopup(false))}
                        >
                            Cancel
                        </button>
                        <button 
                        className="btn-green py-2 px-4 text-white"
                        disabled={createLoading}
                        >
                            {
                                createLoading 
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Submit'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateTicketPopup