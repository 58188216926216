import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import { deposit_create } from '../../redux/actions/depositActions';
import { useDispatch } from 'react-redux';


const Success = () => {
  const location = useLocation();
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const amount = query.get('amount');
  const payment = query.get('payment_intent');
  useEffect(() => {
    if (amount && payment) {
      dispatch(deposit_create({ amount:amount, tID: payment }));
    }
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);
    return () => clearTimeout(timer);
  }, [amount, payment, dispatch, navigate]);


  return (
    <div className='bg-black h-screen w-full flex flex-col space-y-8 justify-center items-center'>
      <div className='w-24 h-24 bg-green-700 rounded-full flex justify-center items-center'>
        <FaCheck className='text-4xl text-white' />
      </div>
      <p className='text-white text-4xl capitalize font-semibold'>Deposit succeeded</p>
      {amount && <p className='text-white text-xl font-semibold '>Amount: ${amount}</p>}
    </div>
  );
};

export default Success;