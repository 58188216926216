import Axios from "config/api";

const fetcher = (url, user, method = 'GET', data = null) => {
    const config = {
        method,
        url,
        headers: {
            Authorization: `Bearer ${user?.token || ''}`,
            'Content-Type': 'application/json', // Ensure JSON is sent
        },
    };

    if (data) {
        config.data = data; // Attach the request body if provided
    }

    return Axios(config);
};

export default fetcher;
