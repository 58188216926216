import Layout from 'components/global/Layout';
import React, { useEffect,useState,useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/notificationReducer';
import fetcher from 'utils/fetcher';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import moment from 'moment';
import { baseURL } from 'config/api';
import Pagination from 'components/global/pagination';
import { BsThreeDotsVertical } from "react-icons/bs";
import Deletepopup from '../../components/notification/deleteallpopup/deletepopup';
import Notificationoption from 'components/notification/notificationoption/notificationoption'
import useClickOutside from 'utils/clickOutside';
import { useNavigate } from 'react-router-dom';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';




const Notifications = () => {
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading,setLoading]=useState();
    const [visibleCount,setVisibleCount]=useState(4)
    useClickOutside(dropdownRef, () => handleToggle(false));
    const navigate=useNavigate();

    const { docs : notifications ,  } = useSelector(state => state.notification);

    const querykey = ['fetch-notifications' ]
    const { isLoading , data } = useQuery(querykey , () => {
        return fetcher(`/notification/my`, user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { notifications  } } } = data;
            dispatch(setDocs(notifications));
        }
    }, [data]);
    const handleDeleteAllClick = () => {
        setDeletePopupOpen(true);
    };
    const closeDeletePopup = () => {
        setDeletePopupOpen(false);
    };

    const handleToggle = () => setIsOpen(prev => !prev);

      const handleNotificationClick = async (item) => {
        try {
            await fetcher(`/notification/my/${item._id}`, user, 'PUT', { isSeen: true });
            if (item.type === "withdraw") {
                navigate('/widthdraw'); 
            } else if (item.type === "deposit") {
                navigate('/earnings');
            }
        } catch (error) {
            console.error("Error marking notification as seen:", error);
            
        }
    };
   
    const loadMoreNotifications=()=>{
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setVisibleCount(prevCount => prevCount + 4);
          }, 1000);
     

      
    }

    return (
        <Layout>
            <div 
            className="bg-pure bg-opacity-60 rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6 flex flex-col space-y-4">
            {isDeletePopupOpen &&(
                <Deletepopup onClose={closeDeletePopup}/>
            ) } 
              <div className='flex justify-between mb-2 w-full relative'>
                    <h3 className='font-semibold text-white'>
                        Notifications
                    </h3>
                <div className='text-white text-2xl cursor-pointer ' onClick={()=>handleToggle()}>
                    <BsThreeDotsVertical/>
                </div>
               {isOpen &&(
                <div className='absolute right-5 top-3 ' ref={dropdownRef}>
               <Notificationoption  onOpen={handleDeleteAllClick} onClose={handleToggle}/>
               </div>
               )} 
              </div>
                
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    notifications?.length > 0 
                    ? 
                        <>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-6">
                            {
                                notifications?.slice(0, visibleCount).map(item => (
                                    <div key={item?._id} className={`shadow-bg  p-3 rounded-md flex gap-4  ${item.isSeen ? '' : 'bg-[rgba(81,8,126,0.2)]'}   cursor-pointer
                                     `} onClick={() => handleNotificationClick(item)}>
                                        <a 
                                        href={item?.imageUrl} 
                                        target='_blank'
                                        className='w-[80px]'
                                        >
                                            <img 
                                            src={baseURL + item?.image} 
                                            alt="" 
                                            className='w-[80px]'
                                            />
                                        </a>
                                        <div className='flex-1'>
                                            <div className='flex sm:items-center justify-between sm:flex-row flex-col'>
                                                <h3 className='text-xl text-primary font-semibold'>
                                                    {item?.name}
                                                </h3>
                                                <div className='sm:text-sm text-xs text-gray-300'>
                                                    <TimeAgo 
                                                    date={new Date(item?.createdAt)} 
                                                    />
                                                </div>
                                            </div>
                                            <p className='text-gray-300 text-sm mt-2'>
                                                {item?.description}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        {visibleCount < notifications.length && ( 
                            <div className='w-full flex justify-center mt-4'>
                                <button className='btn-primary text-white w-[120px] py-2' onClick={loadMoreNotifications}>
                                    {loading ? <PulseLoader size={10} color='#fff' /> :  'Load More'}
                                </button>
                            </div>
                        )}
                        </>
                    :
                        <ItemNotFound message='No Notification found.' />
                }

            

            
            </div>


            
        </Layout>
    )
}

export default Notifications