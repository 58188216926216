import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import Compaign from 'components/home/Compaign'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import fetcher from 'utils/fetcher';

const LatestFlipCompaigns = () => {
    const { user } = useSelector(state => state.auth);
    const [compaings , setCompaigns] = useState([]);

    const { isLoading , data } = useQuery('fetch-my-latest-compaigns' , () => {
        return fetcher(`/compaign/my/latest-compaigns` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs } } } = data;
            setCompaigns(docs);
        }
    } , [data] )

    return (
        <div className='py-6 '>
            <h4 className='heading-sm text-gradient'>
                Your Latest Flip Compaigns
            </h4>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                compaings?.length > 0 
                ?
                    <div className='flex flex-col gap-4 lg:w-1/2 md:w-2/3  w-full mt-2'>
                        {
                            compaings.map(item => (
                                <Compaign 
                                key={item}
                                compaign={item}
                                />
                            ))
                        }
                    </div>
                :
                    <ItemNotFound message='You have not created any compaign yet.' />
            }
        </div>
    )
}

export default LatestFlipCompaigns