import React from 'react'

const HamburgerSvg = () => {
    return (
        <div>
            <svg width="30px" height="30px" viewBox="0 0 24 24" version="1.1" >
            <title>Menu</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
             <g id="Menu">
            <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24">

            </rect>
            <line x1="5" y1="7" x2="19" y2="7" id="Path" stroke="#ffffff" strokeWidth="2" strokeLinecap="round">

            </line>
            <line x1="5" y1="17" x2="19" y2="17" id="Path" stroke="#ffffff" strokeWidth="2" strokeLinecap="round">

                </line>
                    <line x1="5" y1="12" x2="19" y2="12" id="Path" stroke="#ffffff" strokeWidth="2" strokeLinecap="round">
                </line>
                </g>
            </g>
        </svg>
        </div>
    )
}

export default HamburgerSvg