import SettingsLayout from 'components/settings/SettingsLayout'
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollableFeed from 'react-scrollable-feed';
import TimeAgo from 'react-timeago'
import { message_getDocs } from 'redux/actions/messageActions';
import fileChangeHandler from 'utils/fileChangeHandler';
import Axios, { baseURL } from 'config/api';
import { ClipLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import { addDoc, setDocs } from 'redux/reducers/messageReducer';
import toastError from 'utils/toastError';
import Loader from 'components/global/Loader';
import BackBtn from 'components/global/BackBtn';

const TicketDetails = () => {
    const { id } = useParams();
    const imgRef = useRef();
    const chatContainerRef = useRef(null);
    const dispatch = useDispatch();
    const [message , setMessage] = useState([]);
    const [sendMessageLoading , setSendMessageLoading] = useState(false);
    const [messageType , setMessageType] = useState('text');
    const [fileName , setFileName] = useState('');
    const [newMessage , setNewMessage] = useState('');
    const [isTyping , setIsTyping] = useState(false);

    const [selectedImage , setSelectedImage] = useState('');

    const { docs : messages , loading } = useSelector(state => state.message);
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(message_getDocs(id));
    }, []);

    useEffect(() => {
        if(!chatContainerRef.current) return;
        chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight;
    }, [messages , chatContainerRef]);

    const sendMessageHandler = async (e) => {
        e.preventDefault();
        const newMessageData = {
            message ,
            sender : user?._id ,
            ticketId : id ,
            type : messageType ,
            senderType : "User"
        }

        try {
            setSendMessageLoading(true);
            const { data : { data : { doc } } } = await Axios.post('/message' , newMessageData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            dispatch(setDocs([...messages , doc ]));
            // socket?.emit('new-message' , doc);
            setMessage('');
            setFileName('');
            setMessageType('text')
            setSendMessageLoading(false);
        } catch (error) {
            setSendMessageLoading(false);
            toastError(error);
        }
    }

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        // socket?.emit('start-typing' , chat?._id);

        // if (typingTimeout) clearTimeout(typingTimeout);

        // setTypingTimeout(
        //     setTimeout(() => {
        //         // socket?.emit("stop-typing" , chat?._id);
        //     }, 1000)
        // );
    }


    return (
        <SettingsLayout>
            <div className="bg-white bg-opacity-60 rounded-lg sm:p-4 px-2 py-4 min-h-[90vh] mb-6">
                <BackBtn />
                <div className='shadow-bg bg-pure mt-4'>
                    <div className='py-4 px-4 flex items-center gap-4 border-b'>
                        <div className='border rounded-full p-1 relative'>
                            <img 
                            src={'https://cdn3.vectorstock.com/i/1000x1000/30/97/flat-business-man-user-profile-avatar-icon-vector-4333097.jpg'} 
                            alt={'Umair Ahmad'} 
                            className='w-[50px] h-[50px] rounded-full object-cover'
                            />
                            <div className='absolute top-2 right-0 bg-gradient rounded-full w-[10px] h-[10px]'>

                            </div>
                        </div>
                        <div className='font-semibold'>
                            <p>Support Agent</p>
                            {/* <p className='text-sm text-gray-500'>{chat?.admin?.phone}</p> */}
                        </div>
                    </div>
                    <div>
                    {
                        loading 
                        ? 
                            <Loader />
                        :
                        messages?.length > 0
                        ? 
                            <ScrollableFeed>
                                <div className='flex flex-col gap-4 p-4 h-[350px] lg:h-[400px] xl:h-[550px] overflow-auto '
                                ref={chatContainerRef}
                                >
                                    {
                                        messages?.map((item , i) => (
                                            <div 
                                            className={`
                                            ${item?.sender?._id === user?._id   ? 'flex justify-end' : ''}`} 
                                            key={i}>
                                                <div className='flex flex-col '>
                                                {
                                                    item?.type === 'text' 
                                                    ? 
                                                    <div className={`w-fit max-w-[400px] rounded-md border p-3 text-[15px] 
                                                    ${item?.sender?._id === user?._id  ? "bg-primary text-white" : "bg-gray-200 text-black"}
                                                    `}>
                                                        {item?.message}
                                                    </div>
                                                    : 
                                                    <div className={`max-w-[300px] overflow-hidden rounded-md border text-[15px] cursor-pointer
                                                    ${item?.sender?._id === user?._id  ? "bg-primary text-white" : "bg-gray-200 text-black"}
                                                    `}
                                                    // onClick={() => imageClickHandler(item?.message)}
                                                    >
                                                        <img 
                                                        src={baseURL + item?.message}
                                                        alt="message" 
                                                        className='w-full h-auto'
                                                        />
                                                    </div>
                                                }
                                                <div className='text-xs '>
                                                    <TimeAgo date={new Date(item?.createdAt)} />
                                                </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ScrollableFeed>
                            :
                           <div className='w-full h-[350px] flex items-center justify-center font-semibold text-lg'>
                                No Message Found.
                            </div>
                        } 
                    </div>
                    {/* { isTyping && <p className='ml-2'>Typing...</p>} */}
                    <div className='w-full table-header-shadow rounded-md flex items-center gap-3 mt-4 border-t border-t-gray-300'>
                        <form 
                        className='flex items-center justify-between w-full px-3 gap-4 py-6'
                        onSubmit={sendMessageHandler}
                        >
                            <div className='w-fit'>
                                <input 
                                type="file" 
                                ref={imgRef} 
                                onChange={e => fileChangeHandler(e , setMessage)}
                                hidden 
                                accept=".jpeg, .jpg, .png"
                                />
                                <div className='cursor-pointer round-shadow rounded-full w-[40px] h-[40px] bg-gray-200 flex items-center justify-center text-xl'
                                onClick={() => imgRef.current.click() }>
                                    <i className="uil uil-camera"></i>
                                </div>
                            </div>
                            <div className='w-full'>
                                <input 
                                placeholder='Write message here...'
                                className='rounded-full w-full py-2 px-4   bg-transparent outline-none  border border-gray-400 focus:border-primary'
                                value={messageType === 'file' ? fileName : message}
                                onChange={handleInputChange}
                                readOnly={messageType==='file'}
                                />
                            </div>
                            <div className='flex-[0.1]'>
                                <button 
                                className='btn-primary py-2 px-4'
                                disabled={!message || sendMessageLoading}
                                >
                                    {
                                        sendMessageLoading 
                                        ? 
                                            <ClipLoader size={20} color='white' />
                                        : 
                                            'Send'
                                    } 
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </SettingsLayout>
    )
}

export default TicketDetails