import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners';
import { logout } from 'redux/actions/authActions';

const UserMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user , loading } = useSelector(state => state.auth);

    const logoutHandler = () => {
        dispatch(logout(navigate));
    }

    return (
        <div className='absolute top-10 right-0 bg-primary rounded-md p-4 w-[250px] text-left text-sm'>
            <div className='border-b border-gray-400 pb-4'>
                <Link 
                to='/manage-account/profile'
                className='text-white text-left hover:text-secondary'
                >
                    <p className='font-semibold text-sm '>
                        {user?.username}
                    </p>
                    <p className='text-xs mt-1 '> Manage Your Account</p>
                </Link>
                {/* <Link to='/' className='flex items-center mt-6 gap-2 hover:text-secondary'>
                    <p>Membership</p>
                    <p className='bg-secondary px-2 py-1 rounded-sm text-xs text-pure'>Basic</p>
                </Link> */}

            </div>
            <div className='font-medium flex flex-col gap-4 border-b border-b-gray-400 py-4'>
                <Link to='/settings/support'
                className='hover:text-secondary text-white'
                >
                    Help
                </Link>
                <Link to='/'
                className='hover:text-secondary text-white'
                >
                    Terms And Conditions
                </Link>
                <Link to='/'
                className='hover:text-secondary text-white'
                >
                    Privacy Policy
                </Link>
            </div>
            <div 
            className='flex items-center justify-between font-medium text-white pt-4 pb-1 hover:text-secondary cursor-pointer'
            onClick={logoutHandler}
            >
                {
                    loading 
                    ? 
                        <ClipLoader size={15} color='white' />
                    : 
                    <p>Logout</p>
                }
                <i className="uil uil-signout text-xl text-white"></i>
            </div>
        </div>
    )
}

export default UserMenu