import SettingsSidebar from './SettingsSidebar';
import Layout from 'components/global/Layout';
import SettingDrawerBtn from './SettingDrawerBtn';

const SettingsLayout = ({ children }) => {
    

    return (
        <Layout>
            <SettingsSidebar />
            <div className='sm:pl-[250px] h-full'>
                <SettingDrawerBtn />
                {children}
            </div>
        </Layout>
    )
}

export default SettingsLayout