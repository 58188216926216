
const fileChangeHandler = (e , setImage) => {
    console.log('running')
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        setImage(reader.result);
    }
}

export default fileChangeHandler;