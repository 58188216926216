import React from 'react'

const EyeSlashSvg = () => {
    return (
        <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.69 11.7C20.57 11.44 17.83 5.25 12 5.25C11.4418 5.24942 10.8851 5.30977 10.34 5.43C10.1604 5.48559 10.0083 5.60656 9.91369 5.76899C9.81908 5.93141 9.78892 6.12343 9.82916 6.30704C9.8694 6.49064 9.97712 6.65244 10.131 6.76041C10.2849 6.86837 10.4736 6.91462 10.66 6.89C11.1007 6.79789 11.5497 6.75098 12 6.75C16.18 6.75 18.58 10.85 19.17 12C18.8103 12.7028 18.3886 13.3721 17.91 14C17.824 14.1107 17.7708 14.2433 17.7564 14.3827C17.7419 14.5221 17.7668 14.6627 17.8282 14.7887C17.8897 14.9147 17.9851 15.0209 18.1039 15.0954C18.2226 15.1699 18.3598 15.2096 18.5 15.21C18.6139 15.2096 18.7262 15.1833 18.8285 15.1331C18.9307 15.0828 19.0201 15.01 19.09 14.92C19.7198 14.1202 20.2566 13.2512 20.69 12.33C20.7338 12.2308 20.7564 12.1235 20.7564 12.015C20.7564 11.9065 20.7338 11.7992 20.69 11.7Z" fill="#fff"/>
        <path d="M6.52999 5.47003C6.38781 5.33755 6.19976 5.26543 6.00546 5.26885C5.81116 5.27228 5.62578 5.35099 5.48836 5.48841C5.35095 5.62582 5.27224 5.81121 5.26881 6.00551C5.26538 6.19981 5.33751 6.38785 5.46999 6.53003L6.38999 7.45003C5.08727 8.64844 4.03971 10.0973 3.30999 11.71C3.27066 11.8034 3.2504 11.9037 3.2504 12.005C3.2504 12.1064 3.27066 12.2067 3.30999 12.3C3.42999 12.56 6.16999 18.75 12 18.75C13.5593 18.7577 15.0863 18.3056 16.39 17.45L17.47 18.53C17.6106 18.6705 17.8012 18.7494 18 18.7494C18.1987 18.7494 18.3894 18.6705 18.53 18.53C18.6704 18.3894 18.7493 18.1988 18.7493 18C18.7493 17.8013 18.6704 17.6107 18.53 17.47L6.52999 5.47003ZM10.36 11.47L12.57 13.69C12.2603 13.7927 11.9285 13.8097 11.6099 13.7393C11.2913 13.6689 10.9976 13.5137 10.76 13.29C10.518 13.0514 10.3511 12.7472 10.2801 12.4149C10.209 12.0826 10.2368 11.7367 10.36 11.42V11.47ZM12 17.25C7.80999 17.25 5.41999 13.14 4.82999 12C5.48267 10.6863 6.37068 9.50345 7.44999 8.51003L9.23999 10.3C8.85581 10.9209 8.69263 11.6534 8.7769 12.3787C8.86116 13.104 9.18793 13.7795 9.70422 14.2958C10.2205 14.8121 10.8961 15.1389 11.6213 15.2231C12.3466 15.3074 13.0791 15.1442 13.7 14.76L15.31 16.37C14.3052 16.954 13.1622 17.2579 12 17.25Z" fill="#fff"/>
        </svg>
    )
}

export default EyeSlashSvg