import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import remove from '../../assets/svgs/removepopup.svg';
import { PulseLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const DepositPopup = ({ onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ispaymentLoading, setIsPaymentLoading] = useState(false); 
    const [amountpay, setAmountPay] = useState(true);
    const [paymentmethod, setPaymentmethod] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [error, setError] = useState(null);
    const amounts = [10, 20, 50, 100, 200];

    const handleAmountClick = (amount) => {
        setSelectedAmount(amount);
        setError(null);
    };

    const handleInputChange = (e) => {
        const value = e.target.value; 
        if (value === '') {
            setSelectedAmount(null);
            setError(null); 
            return;
        }  
        const parsedValue = parseFloat(value); 
        if (!isNaN(parsedValue) && parsedValue >= 1) {
            setSelectedAmount(parsedValue);
            setError(null);
        } else {
            setError('Please enter a valid amount'); 
            setSelectedAmount(null);
        }
    };




    
    const handleMobilePayClick = async () => {
        if (selectedAmount) {
            setIsPaymentLoading(true);
            const randomEmail = `user${Math.floor(Math.random() * 10000)}@example.com`;
            const webAppBaseURL = 'http://localhost:3000'; 
            const callbackUrl = `${webAppBaseURL}/paymentcallback`; 
    
            // Create the payload
            const payload = {
                email: randomEmail,
                amount: selectedAmount ,
                callback_url: callbackUrl,
                metadata: JSON.stringify({
                    cancel_action: `${webAppBaseURL}/paymentcallback`, 
                }),
                reference: Date.now() + '-' + Math.round(Math.random() * 1E9),
                channels: ["mobile_money"],
            };
    
            try {
                const response = await fetch('http://localhost:8000/api/initialize-paystack-transaction', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(payload), 
                });
    
                const data = await response.json();
    
                if (response.ok) {
                
                    window.location.href = data.data.authorization_url; 
                } else {
                  
                    setError(data.data.message || 'Transaction initialization failed.');
                }
            } catch (error) {
                console.error('Error during submission:', error);
                setError('An error occurred. Please try again.');
            } finally {
                setIsPaymentLoading(false);
            }
        } else {
            setError('Invalid amount.');
        }
    };
    



    const handleSubmit = async () => {
        if (selectedAmount) {
            setLoading(true); 
            try {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                navigate('/pay', { state: { amount: selectedAmount } });
            } catch (error) {
                console.error('Error during submission:', error);
                setError('An error occurred. Please try again.');
            } finally {
                setLoading(false);
            }
        } else {
            setError('Invalid amount.');
        }
    };

    const togglePaymentMethod = () => {
        if (selectedAmount) {
            setIsLoading(true);
            setTimeout(() => {
                setAmountPay(false);
                setPaymentmethod(true);
                setIsLoading(false); 
            }, 1000); 
        } else {
            setError('Please select an amount first.');
        }
    };
 




    return (
        <div className="fixed inset-0 h-screen flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="flex flex-col space-y-4 bg-pure rounded-lg p-6 z-50 w-11/12 sm:w-1/2 lg:w-1/3 relative">
                <div className='absolute right-3 top-2 cursor-pointer'>
                    <img src={remove} alt='Close' onClick={onClose} />
                </div>

                {amountpay && (
                    <>
                        <h2 className="text-lg font-bold text-white">Deposit Your Amount</h2>
                        <div>
                            <input 
                                type="number" 
                                placeholder="Amount" 
                                value={selectedAmount !== null ? selectedAmount : ''} 
                                onChange={handleInputChange} 
                                className="border border-primary p-2 w-full outline-none rounded-md text-white"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
                            />
                            {error && <div className="text-red-500 text-xs">{error}</div>}
                        </div>
                        <div className='grid grid-cols-3 gap-3'>
                            {amounts.map(amount => (
                                <div 
                                    key={amount} 
                                    className={`flex items-center justify-center py-1 text-white rounded-[30px] cursor-pointer 
                                        ${selectedAmount === amount ? 'bg-primary' : 'border border-primary'}`} 
                                    onClick={() => handleAmountClick(amount)}
                                >
                                    ${amount}
                                </div>
                            ))}
                        </div>
                        <button className="btn-primary py-2 text-white" onClick={togglePaymentMethod}>
                            {isLoading ? <PulseLoader size={10} color='#fff' /> : 'Proceed to Payment Method'}
                        </button>
                    </>
                )}

                {paymentmethod && (
                    <>
                        <button className="btn-primary py-2 text-white" onClick={handleMobilePayClick}>
                        {ispaymentLoading ? <PulseLoader size={10} color='#fff' /> :  'Pay with Mobile'}
                        </button>
                        <button className="btn-primary py-2 text-white" onClick={handleSubmit}>
                        {loading ? <PulseLoader size={10} color='#fff' /> :  'Pay with Other  '}
                        </button>

                
                      
                    </>
                )}
            </div>
        </div>
    );
};

export default DepositPopup;
