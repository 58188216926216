import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import RequestStatus from 'components/global/RequestStatus';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { setCurrentPage } from 'redux/reducers/teamReducer';


const ReferralLinksTable = ({ isLoading }) => {

    const { docs , currentPage , pages } = useSelector(state => state.team);

    return (
        <div className=" shadow-bg overflow-x-auto rounded-lg">
            <Table className="w-full table-auto overflow-x-auto ">
                <Thead className="border-b text-sm bg-primary text-pure">
                    <Tr className='bg-primary text-white'>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            User ID
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Username
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Joined Date
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Flip Compaign
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Flip Shared 
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Earning From Referral
                        </Th>
                        
                    </Tr>
                </Thead>
                <Tbody className='text-sm'>
                {
                    isLoading 
                    ? 
                        <tr>
                            <td colSpan={7}>
                                <Loader />
                            </td>
                        </tr>
                    :
                    docs?.length > 0 
                    ? 
                        docs.map((item , i) => (
                        <Tr
                        key={item?._Id} 
                        className="bg-pure border-b transition duration-300 ease-in-out"
                        >
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?._id.slice(0,10)}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.username}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {moment(item?.createdAt).format('DD MMM YYYY')}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.flipCompaignsCount}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.flipSharedCount}
                            </Td>
                            <Td className="text-white px-6 py-4 whitespace-nowrap sm:text-center text-right ">
                                ${item?.referralCommission}
                            </Td>
                            
                        </Tr>
                        ))
                    : 
                        <tr>
                            <td colSpan={7}>
                                <ItemNotFound message='No Team member found.' />
                            </td>
                        </tr>
                }
                
                </Tbody>
            </Table>
           
            <Pagination 
            currentPage={currentPage}
            pageCount={pages}
            setPage={setCurrentPage}
            />
        </div>
    )
}

export default ReferralLinksTable;