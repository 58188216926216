import HorizentalDots from 'assets/svgs/HorizentalDots';
import React, { useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useClickOutside from 'utils/clickOutside';

const ManageAccountBar = () => {
    const dropMenuRef = useRef(null);
    const [showDrop , setShowDrop] = useState(false);
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname.split('/').includes(path);
    }

    useClickOutside(dropMenuRef , () => setShowDrop(false));

    return (
        <div className='bg-pure rounded-md px-4 text-sm'>
            <div className='flex items-center justify-between gap-4'>
                <ul className='flex items-center sm:gap-8 gap-4 sm:text-sm text-xs '>
                    <li 
                    className={`py-4 text-grayText 
                    ${isActive('profile') ? 'border-b-2 border-b-primary font-semibold text-white' : 'border-b-2 border-transparent'}`
                    }
                    >
                        <Link to='/manage-account/profile'>
                            Profile Information
                        </Link>
                    </li>
                    {/* <li 
                    className={`py-4 text-grayText 
                    ${isActive('membership') ? 'border-b-2 border-b-primary font-semibold' : 'border-b-2 border-transparent'}`
                    }
                    >
                        <Link to='/manage-account/membership'>
                            Membership Details
                        </Link>
                    </li> */}
                    <li 
                    className={`py-4 text-grayText  sm:block hidden
                    ${isActive('compaigns') ? 'border-b-2 border-b-primary font-semibold text-white' : 'border-b-2 border-transparent'}`
                    }
                    >
                        <Link to='/manage-account/compaigns'>
                            Compaigns
                        </Link>
                    </li>
                    <li 
                    className={`py-4 text-grayText md:block hidden
                    ${isActive('payment-history') ? 'border-b-2 border-b-primary font-semibold text-white' : 'border-b-2 border-transparent'}`
                    }
                    >
                        <Link to='/manage-account/payment-history'>
                            Payment History
                        </Link>
                    </li>

                </ul>
                <div className='relative lg:hidden block'>
                    <button
                    onClick={() => {
                        setShowDrop(true)
                    }}
                    >
                        <HorizentalDots />
                    </button>
                    {
                        showDrop &&
                            <ul 
                            ref={dropMenuRef}
                            className='absolute bg-pure w-[180px] right-0 top-full shadow-bg py-4 px-2 flex flex-col gap-1'>
                                <li 
                                className={`py-2 sm:hidden block hover:bg-gray-100 rounded-md px-2 text-grayText 
                                ${isActive('compaigns') ? 'bg-primary text-white' : ''}`
                                }
                                >
                                    <Link to='/manage-account/compaigns'>
                                        Compaigns
                                    </Link>
                                </li>
                                <li 
                                className={`py-2 md:hidden block hover:bg-gray-100 rounded-md px-2 text-grayText 
                                ${isActive('payment-history') ? 'bg-primary text-white' : ''}`
                                }
                                >
                                    <Link to='/manage-account/payment-history'>
                                        Payment History
                                    </Link>
                                </li>
                            </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default ManageAccountBar