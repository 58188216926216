import React from 'react'

const UserSvg = () => {
    return (
        <div>
            <svg width="21" height="21" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 0C5.14718 0 0 5.14718 0 11.5C0 17.8528 5.14718 23 11.5 23C17.8528 23 23 17.8528 23 11.5C23 5.14718 17.8528 0 11.5 0ZM11.5 4.45161C13.7536 4.45161 15.5806 6.27863 15.5806 8.53226C15.5806 10.7859 13.7536 12.6129 11.5 12.6129C9.24637 12.6129 7.41935 10.7859 7.41935 8.53226C7.41935 6.27863 9.24637 4.45161 11.5 4.45161ZM11.5 20.4032C8.77802 20.4032 6.33891 19.1698 4.70665 17.2407C5.57843 15.5992 7.28488 14.4677 9.27419 14.4677C9.38548 14.4677 9.49677 14.4863 9.60343 14.5188C10.2063 14.7135 10.8369 14.8387 11.5 14.8387C12.1631 14.8387 12.7984 14.7135 13.3966 14.5188C13.5032 14.4863 13.6145 14.4677 13.7258 14.4677C15.7151 14.4677 17.4216 15.5992 18.2933 17.2407C16.6611 19.1698 14.222 20.4032 11.5 20.4032Z" fill="white"/>
</svg>


        </div>
    )
}

export default UserSvg