import React, { useState,useEffect } from 'react';
import Layout from 'components/global/Layout';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Widthdrawoption from '../widthdrawoption/index';
import moment from 'moment';
import Loader from 'components/global/Loader';
import { useDispatch,useSelector } from 'react-redux';
import ItemNotFound from 'components/global/ItemNotFound';
import { fetchWithdraw,fetchCreateWithdraw,fetchSingleWithdraw  } from '../../redux/actions/withdrawActions';
import { FaEdit } from 'react-icons/fa';
import {compaign_getDetails} from '../../redux/actions/compaignActions';



const Widthdraw = () => {
   const dispatch=useDispatch();
   const { withdraw , loading , error} = useSelector(state => state.withdraws);
    const { user } = useSelector(state => state.auth);
  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
  const [selectedWithdrawId, setSelectedWithdrawId] = useState(null);
 

  const handleWithdrawClick = (id) => {
    setSelectedWithdrawId(id);
    setIsWithdrawOpen(true);
    if (id) {
      dispatch(fetchSingleWithdraw(id));
    } 
  };

  const handleCloseWithdraw = () => {
    setIsWithdrawOpen(false);

  };
  useEffect(()=>{
      dispatch(fetchWithdraw())
  },[dispatch])


  return (
    <Layout>
      <div className='sm:p-4 px-2 py-4 min-h-screen mb-6 h-full'>
      <div className='flex flex-col space-y-3'>
      <div className='flex flex-row w-full py-4'>
          <div className=' w-full md:w-[500px] h-auto bg-pure rounded-lg flex flex-col gap-3 px-3 pb-6  '>
            <div className='text-white text-opacity-75 text-lg text-start pt-5 font-semibold'>
              Available Balance
            </div>
            <div className='text-gradient text-lg text-start font-semibold'>  USD {user?.wallet?.totalBalance}</div>
            <div className='flex flex-row w-full gap-3'>
              <button className="btn-secondary py-2 w-full" onClick={() => handleWithdrawClick(null)}>
                Withdraw
              </button>

            </div>
          </div>
        </div>

        <div>
          <div className='flex items-center justify-between mb-2'>
            <h4 className='text-base font-semibold text-white'>Recent Trasnsactions</h4>
            <h4 className='text-sm font-semibold text-primary capitalize'>all</h4>

          </div>
          <div className="shadow-bg overflow-x-auto rounded-lg">
            <Table className="w-full table-auto overflow-x-auto">
              <Thead className="border-b text-sm bg-primary text-pure">
                <Tr className='bg-primary text-white'>
                  <Th scope="col" className="font-medium px-6 py-4 text-center">Date</Th>
                  <Th scope="col" className="font-medium px-6 py-4 text-center">Amount</Th>
                  <Th scope="col" className="font-medium px-6 py-4 text-center">Email</Th>
                  <Th scope="col" className="font-medium px-6 py-4 text-center">Description</Th>
                  <Th scope="col" className="font-medium px-6 py-4 text-center">Status</Th>
                  <Th scope="col" className="font-medium px-6 py-4 text-center">Action</Th>


                </Tr>
              </Thead>
              <Tbody className='text-sm'>
                    {
                        loading 
                    ? 
                        <tr>
                            <td colSpan={6}>
                                <Loader />
                            </td>
                        </tr>
                    :
                    withdraw?.length > 0 
                    ? 
                    withdraw?.map((item , i) => (
                            <Tr
                            key={item._id} 
                            className="bg-pure border-b transition duration-300 ease-in-out"
                            >
                                <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {moment(item?.createdAt).format('DD MMM YYYY')}
                                </Td>
                                <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                  {item?.amount}
                                </Td>
                                <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                {item?.user?.email}
                                 </Td>
                                 <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                {item?.description}
                                 </Td>
                                 <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                 {item?.status}
                                 </Td>
                                 <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                 <div className='flex flex-row  justify-end lg:justify-center space-x-3 '>
                                 <button
                              className={`text-lg ${item.status === 'completed' ? 'text-gray-500 cursor-not-allowed' : 'text-green-500'}`}
                              onClick={() => item.status !== 'completed' && handleWithdrawClick(item._id)}
                              disabled={item.status === 'completed'}
                            >
                              <FaEdit />
                            </button>
                                    
                                 </div>
                                  
                                  </Td>
                            
                            </Tr>
                            ))
                    
                    : 
                        <tr>
                            <td colSpan={6}>
                                <ItemNotFound message='No  transaction History Found.' />
                            </td>
                        </tr>
                        
                    }
                    </Tbody>
            </Table>
          </div>
        </div>

        {isWithdrawOpen && (
          <Widthdrawoption id={selectedWithdrawId} onClose={handleCloseWithdraw} />
        )}
      </div>
      
      </div>
    </Layout>
  );
};

export default Widthdraw;
