import AngleDownSvg from "assets/svgs/AngleDownSvg";
import AngleTopSvg from "assets/svgs/AngleTopSvg";
import { useEffect, useState } from "react";
import Accordion from "react-accordion-comp";
import { Link } from "react-router-dom";

const Membership = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, []);


    return (
        <div className="bg-pure rounded-lg">
            <div className="flex items-center justify-between gap-4 border-b py-4 sm:px-4 px-2 cursor-pointer"
            onClick={() => setShow(!show)}
            >
                <div className="flex items-center gap-2">
                    <h4 className="heading-sm">
                        Your Membership
                    </h4>
                    {/* <p className="bg-primary px-2 py-1 rounded-full text-pure text-[10px]">Basic Plan</p> */}
                </div>
                <div 
                className="cursor-pointer"
                >
                    {
                        show
                        ? 
                            <AngleTopSvg size={25} />
                        : 
                            <AngleDownSvg size={25} />
                    }
                </div>
            </div>
            <Accordion isOpen={show}>
                <div className="py-3 sm:px-4 px-2 text-sm flex flex-col gap-3 text-white">
                    <h6 className="font-medium ">Flat Fee Membership Plan</h6>
                    <p>Charge a fixed amount for each sponsored
                    link placement regardless of the number of
                    impressions or clicks it receives.</p>
                    <button className="btn-secondary py-2 px-4">
                        View All Plans
                    </button>
                </div>
            </Accordion>
        </div>
    )
}

export default Membership