import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
    name : 'message' ,
    initialState : {
        docs : [] ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        loading : false ,
        createLoading : false ,
    } ,
    reducers : {
        addDoc (state , action) {
            state.docs = state.docs.push(action.payload);
        } ,
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setCreateLoading (state , action) {
            state.createLoading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
    
    }
    
});

export const { setDocs , setLoading , setCurrentPage , setPages , setDocsCount , setCreateLoading , addDoc } = messageSlice.actions;
export default messageSlice.reducer;


