import React from 'react'

const ArrowRight = () => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.60487 0.392126L7.49087 5.70213C7.74287 5.91813 7.74287 6.31413 7.49087 6.53013L1.60487 11.8401C1.22687 12.1821 0.614868 11.9301 0.614868 11.4261L0.614868 0.806127C0.614868 0.302127 1.22687 0.0501261 1.60487 0.392126Z" fill="var(--primary)"/>
        </svg>

    )
}

export default ArrowRight