import React from 'react'

const CompaignDepositPopup = ({ onClose, onConfirm }) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-pure p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold text-white">Insufficient Balance</h2>
                <p className='text-white'>You have insufficient balance in your wallet to create this campaign. Would you like to deposit funds?</p>
                <div className="flex flex-col sm:flex-row justify-end mt-4 gap-4">
                    <button 
                        onClick={onClose} 
                        className="btn-secondary w-full sm:w-1/2 lg:w-24 py-2"
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={onConfirm} 
                        className="btn-primary w-full sm:w-1/2 lg:w-24 py-2 text-white"
                    >
                        Deposit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CompaignDepositPopup;