import PaymentSvg from 'assets/svgs/PaymentSvg'
import SettingsLayout from 'components/settings/SettingsLayout'
import React from 'react'

const Payment = () => {
    return (
        <SettingsLayout>
            <div className=" bg-pure rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6">
                <div className='flex items-center justify-between gap-4 border-b pb-4'>
                    <div>
                        <h3 className='font-semibold text-[15px] text-white'>Payment Details</h3>
                        <small className='text-gray-300'>Update your payment details for a smooth withdrawal process.</small>
                    </div>
                    <div className='sm:flex hidden items-center gap-4'>
                        <button className="btn-white py-2 px-4 text-sm">
                            Cancel
                        </button>
                        <button className="btn-green py-2 px-4 text-sm">
                            Save
                        </button>
                    </div>
                </div>
                <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-10'>
                    {
                        [...Array(3).keys()].map(item => (
                            <div className='bg-grayText h-[80px]   text-white rounded-lg flex items-center px-4 py-4 gap-4 font-medium'>
                                <PaymentSvg />
                                <p>Paypal</p>
                            </div>
                        ))
                    }
                </div>
                <div className='mt-2'>
                    <small className='text-gray-300'>
                    PayPal available in USD ($) only.
                    </small>
                </div>
                <div className='sm:hidden flex items-center gap-4 mt-6 justify-end'>
                    <button className="btn-white py-2 px-4 text-sm">
                        Cancel
                    </button>
                    <button className="btn-green py-2 px-4 text-sm">
                        Save
                    </button>
                </div>
            </div>
        </SettingsLayout>
    )
}

export default Payment