import React,{useState} from 'react'
import { PulseLoader } from 'react-spinners';
import remove from '../../assets/svgs/removepopup.svg';
import { Navigate, useNavigate } from 'react-router-dom';

const Remaningamountpopup = ({remaining,onClose,budgetAmount}) => {
    const navigate=useNavigate();
    const [amountpay, setAmountPay] = useState(true);
    const [paymentmethod, setPaymentmethod] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ispaymentLoading, setIsPaymentLoading] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
     const [error,setError]=useState(null);


    const togglePaymentMethod = () => {
        if (remaining) {
            setIsLoading(true);
            setTimeout(() => {
                setAmountPay(false);
                setPaymentmethod(true);
                setIsLoading(false); 
            }, 1000); 
        } else {
            setError('ERROR.');
        }
    };  
    
    
    const handleMobilePayClick = async () => {
        if (remaining) {
            setIsPaymentLoading(true);
            const randomEmail = `user${Math.floor(Math.random() * 10000)}@example.com`;
            const webAppBaseURL = 'http://localhost:3000'; 
            const callbackUrl = `${webAppBaseURL}/paymentcallback`; 
    
            // Create the payload
            const payload = {
                email: randomEmail,
                amount: remaining ,
                callback_url: callbackUrl,
                metadata: JSON.stringify({
                    cancel_action: `${webAppBaseURL}/paymentcallback`, 
                }),
                reference: Date.now() + '-' + Math.round(Math.random() * 1E9),
                channels: ["mobile_money"],
            };
    
            try {
                const response = await fetch('http://localhost:8000/api/initialize-paystack-transaction', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(payload), 
                });
    
                const data = await response.json();
    
                if (response.ok) {
                
                    window.location.href = data.data.authorization_url; 
                } else {
                  
                    setError(data.data.message || 'Transaction initialization failed.');
                }
            } catch (error) {
                console.error('Error during submission:', error);
                setError('An error occurred. Please try again.');
            } finally {
                setIsPaymentLoading(false);
            }
        } else {
            setError('Invalid amount.');
        }
    };
    



    const handleSubmit = async () => {
        if (remaining) {
            setLoading(true); 
            try {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                navigate('/pay', { state: { amount: remaining } });
            } catch (error) {
                console.error('Error during submission:', error);
                setError('An error occurred. Please try again.');
            } finally {
                setLoading(false);
            }
        } else {
            setError('Invalid amount.');
        }
    };
    
  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
        <div className="flex flex-col space-y-4 bg-pure rounded-lg p-6 z-50 w-11/12 sm:w-1/2 lg:w-1/3  relative">
        <div className='absolute right-3 top-2 cursor-pointer'>
                    <img src={remove} alt='Close' onClick={onClose} />
                </div>

                {amountpay && (
                    <>

                    <h2 className="text-lg font-semibold text-white">Remaining Amount</h2>
                <p className='text-white'>You need to deposit ${remaining} more to reach the target of ${budgetAmount}.</p>
                <div className="flex flex-col sm:flex-row  mt-4 gap-4">
                 
                    <button 
                        className="btn-primary w-full  py-2 text-white" onClick={togglePaymentMethod}>
                       {isLoading ? <PulseLoader size={10} color='#fff' /> : 'Proceed to Payment Method'}
                    </button>
                </div>

                    </>


               ) }


               {paymentmethod && (
                    <>
                        <button className="btn-primary py-2 text-white" onClick={handleMobilePayClick}>
                        {ispaymentLoading ? <PulseLoader size={10} color='#fff' /> :  'Pay with Mobile'}
                        </button>
                        <button className="btn-primary py-2 text-white" onClick={handleSubmit}>
                        {loading ? <PulseLoader size={10} color='#fff' /> :  'Pay with Other  '}
                        </button>

                
                      
                    </>
                )}
               
            </div>
      
    </div>
  )
}

export default Remaningamountpopup;
