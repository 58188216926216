import React, { useRef, useEffect } from 'react';
import 'animate.css';
const Withdrawmessage = ({ messageType, onClose }) => {
  const messageRef = useRef(null);
  const handleClickOutside = (event) => {
    if (messageRef.current && !messageRef.current.contains(event.target)) {
      onClose(); 
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 z-50'>
      <div className='flex justify-center items-center h-screen'>
        <div ref={messageRef} className='w-1/3 bg-pure rounded-md flex flex-col py-3 items-center animate__animated animate__flipInY'>
          <h2 className='text-white font-semibold pt-8 pb-6 text-3xl'>Withdrawal Limits</h2>
          {messageType === 'minimum' && (
            <p className='text-white text-center text-lg'>Minimum: $100 Equivalent in Local Currency</p>
          )}
          {messageType === 'maximum' && (
            <p className='text-white text-center text-lg'>Maximum: $2000 Equivalent in Local Currency</p>
          )}
          <p className='text-white text-center text-xl pt-7 capitalize'>
            Note: Bank withdrawals may result in charges from the Recipient Bank
          </p>
        </div>
      </div>
    </div>
  );
};

export default Withdrawmessage;
