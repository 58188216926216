import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import fetcher from 'utils/fetcher';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';




const Notificationoption = ({onOpen,onClose}) => {
  const { user } = useSelector(state => state.auth);

 
  const handleNotificationClick = async () => {
    try {
        await fetcher(`/notification/my-read-all`, user, 'PUT');
        toast.success("All notifications marked as read!");
        onClose();
    } catch (error) {
        console.error("Error marking notification as seen:", error);
        toast.error("Failed to mark notifications as read.");
    }
};


const handleDeleteAllClick = () => {
  onClose(); 
  onOpen(); 
};


  return (
    <div className="w-28 h-auto bg-[#26292C]  flex flex-col space-y-2 rounded-md text-white   cursor-pointer">
      <div className="hover:bg-primary hover:text-white text-center py-1" onClick={()=>handleNotificationClick()}>Read All</div>
      <div  className="hover:bg-primary hover:text-white text-center py-1" onClick={handleDeleteAllClick}>Delete All</div>
    </div>
  )
}

export default Notificationoption;
