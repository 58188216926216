import React from 'react'

const WalletSvg = () => {
    return (
        <div>
            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.6888 5.66699H4.83464C4.37422 5.66699 4.0013 5.29408 4.0013 4.83366C4.0013 4.37324 4.37422 4.00033 4.83464 4.00033H24.8346C25.2951 4.00033 25.668 3.62741 25.668 3.16699C25.668 1.78626 24.5487 0.666992 23.168 0.666992H4.0013C2.16016 0.666992 0.667969 2.15918 0.667969 4.00033V20.667C0.667969 22.5081 2.16016 24.0003 4.0013 24.0003H24.6888C26.1482 24.0003 27.3346 22.879 27.3346 21.5003V8.16699C27.3346 6.78835 26.1482 5.66699 24.6888 5.66699ZM22.3346 16.5003C21.4143 16.5003 20.668 15.754 20.668 14.8337C20.668 13.9133 21.4143 13.167 22.3346 13.167C23.2549 13.167 24.0013 13.9133 24.0013 14.8337C24.0013 15.754 23.2549 16.5003 22.3346 16.5003Z" fill="#51087E"/>
</svg>

        </div>
    )
}

export default WalletSvg