import React from 'react'

const BellSvg = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 22.8571C11.5768 22.8571 12.8558 21.5781 12.8558 19.9999H7.14421C7.14421 21.5781 8.42322 22.8571 10 22.8571ZM19.6156 16.1736C18.7531 15.2468 17.1393 13.8526 17.1393 9.28569C17.1393 5.81695 14.7071 3.04017 11.4277 2.35892V1.42857C11.4277 0.63973 10.7884 0 10 0C9.21161 0 8.57233 0.63973 8.57233 1.42857V2.35892C5.29287 3.04017 2.86074 5.81695 2.86074 9.28569C2.86074 13.8526 1.2469 15.2468 0.384405 16.1736C0.116548 16.4616 -0.00220127 16.8058 3.08654e-05 17.1428C0.00494156 17.8749 0.579493 18.5714 1.43306 18.5714H18.5669C19.4205 18.5714 19.9955 17.8749 20 17.1428C20.0022 16.8058 19.8835 16.4611 19.6156 16.1736Z" fill="white"/>
</svg>

    )
}

export default BellSvg