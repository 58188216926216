import Layout from 'components/global/Layout'
import AccountDetails from './AccountDetails'
import ManageAccountBar from './ManageAccountBar'


const ManageAccountLayout = ({ children }) => {
    return (
        <Layout>
            <div 
            className="bg-pure rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6"
            >
                <AccountDetails />
                <div className='mt-8'>
                    <ManageAccountBar />
                </div>
                <div className='mt-4'>
                    {children}
                </div>
            </div>
        </Layout>
    )
}

export default ManageAccountLayout