import React,{useState} from "react";
import { useSelector } from "react-redux";
import fetcher from 'utils/fetcher';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import 'animate.css';

const Deletepopup = ({onClose}) => {
  const { user } = useSelector(state => state.auth);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
 const handleDeleteAll = async () => {
    setIsLoading(true); 
    try {
      await fetcher(`notification/my-delete-all`, user, 'DELETE');
      toast.success("All notifications deleted successfully!"); 
      onClose();
    } catch (error) {
      console.error("Error deleting notifications:", error);
      toast.error("Failed to delete notifications.");
    } finally {
      setIsLoading(false); 
    }
  };
  return (
    <div className="fixed inset-0 h-screen flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className={`bg-pure p-6 rounded shadow-lg text-white ${isAnimatingOut ? 'animate__animated animate__flipOutY' : 'animate__animated animate__flipInY'}`}>
        <h2 className="text-lg font-semibold">Confirm Deletion</h2>
        <p>Are you sure you want to delete all items?</p>
        <div className="flex justify-end mt-4">
          <button 
            className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded" 
            onClick={onClose}>
            Cancel
          </button>
          <button 
            className="px-4 py-2 bg-primary text-white rounded"  onClick={()=>handleDeleteAll()} >

            {isLoading ? <PulseLoader size={10} color='#fff' /> :  ' Delete All'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Deletepopup;
