import React, { useState,useEffect } from 'react';
import Layout from 'components/global/Layout';
import Select from 'react-select';
import Withdrawmessage from '../../components/widthdraw/withdrawmessage';
import remove from '../../assets/svgs/removepopup.svg';
import 'animate.css';
import {fetchCreateWithdraw,fetchSingleWithdraw,fetchUpdateWithdraw } from '../../redux/actions/withdrawActions';
import { useDispatch,useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

const payOptions = [
  { value: 1, label: 'Bank Account' },
  { value: 2, label: 'Mobile Money' },
  { value: 3, label: 'Paypal' },
  { value: 4, label: 'Cash App' },
  { value: 5, label: 'Zelle' },
];




const Widthdrawoption = ({onClose,id}) => {
  const dispatch=useDispatch();
  const { createLoading, singleWithdraw,updateLoading,singleLoading } = useSelector(state => ({
    createLoading: state.withdraws.createLoading,
    singleWithdraw: state.withdraws.singlewithdraw, 
    updateLoading: state.withdraws.updateLoading,
    singleLoading: state.withdraws.singleLoading,


  }));
const [accountName,setAccountName]=useState( "" );
const [accountNo,setAccountNo]=useState('');
const [bankName,setBankName]=useState('');
const [bankIBAN,setBankIBAN]=useState('');
const [accountType, setAccountType] = useState('');
const [amount, setAmount] = useState('');
 const [country, setCountry] = useState('Pk'); 
const [showMessage, setShowMessage] = useState(false);
const [messageType, setMessageType] = useState('');
const [isAnimatingOut, setIsAnimatingOut] = useState(false);
const [loading, setLoading] = useState(false);
  const handlePay = (selectedOption) => {
    setLoading(true); 
    setAccountType(selectedOption.value);
    setTimeout(() => {
      setLoading(false); 
    }, 1000); 
  };


  useEffect(() => {
    if (id) {
      dispatch(fetchSingleWithdraw(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && singleWithdraw) {
      setAccountName(singleWithdraw.accountName || "");
      setAccountNo(singleWithdraw.accountNo || "");
      setBankName(singleWithdraw.bankName || '');
      setBankIBAN(singleWithdraw.bankIBAN || "");
      setAmount(singleWithdraw.amount || "");
      setAccountType(singleWithdraw.accountType || "");
    } else {
      setAccountName("");
      setAccountNo("");
      setBankName('');
      setBankIBAN("");
      setAmount("");
      setAccountType("");
    }
  }, [singleWithdraw, id]);


  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue);
    setShowMessage(false); 
    const amountNumber = parseFloat(inputValue);
    if (!isNaN(amountNumber)) {
      if (amountNumber < 100) {
        setMessageType('minimum'); 
        setShowMessage(true);
      } else if (amountNumber > 2000) {
        setMessageType('maximum'); 
        setShowMessage(true);
      }
    }
  };
  
  const handleSubmit = () => {
    const amountValue = parseFloat(amount);
    if (!accountName || !accountNo || !bankIBAN || !amount || !bankName) {
      toast.error('Please fill in all required fields.');
      return;
    }
  
    const data = {
      accountName,
      accountNo,
      bankName,
      bankIBAN,
      accountType,
      amount: amountValue,
      country
    };
  
    if (id) {
      dispatch(fetchUpdateWithdraw(id, data))
        .then(() => {
          console.log('Withdrawal request updated successfully!');
          handleClose();
        })
        .catch((error) => {
          console.log('Failed to update withdrawal request.', error);
        });
    } else {
      dispatch(fetchCreateWithdraw(data))
        .then(() => {
          console.log('Withdrawal request created successfully!');
          handleClose();
        })
        .catch((error) => {
          console.log('Failed to create withdrawal request.', error);
        });
    }
  
    setShowMessage(false);
  };
  
  const handleCloseMessage = () => {
    setShowMessage(false);
  };

 
  const handleClose = () => {
    setIsAnimatingOut(true);
    setTimeout(() => {
 
      onClose();
    }, 300);
  };

 


  
  return (
   
      <div className='fixed top-[-12px] left-0 bg-gray-900 w-full h-screen bg-opacity-30 z-50'>
           {showMessage && (
              <Withdrawmessage messageType={messageType}  onClose={handleCloseMessage}/>
            )}
            <div className='flex justify-center items-center h-screen'>
        <div   className={` w-[90%] sm:w-1/2 lg:w-1/3 h-96 sm:h-auto overflow-auto bg-pure rounded-md flex flex-col gap-3 py-3 relative ${isAnimatingOut ? 'animate__animated animate__flipOutY' : 'animate__animated animate__flipInY'}`}>
        <div className='absolute right-3 top-2 cursor-pointer' onClick={handleClose}>
            <img src={remove} alt='Close' />
          </div>
          {

            singleLoading ?
            (
              <div className='w-full h-60 flex justify-center'>
              <Loader/>
              </div>
            ):(
              <div className='w-full flex flex-col space-y-4 px-3 pt-10 '>

<h1 className='text-white text-center text-lg'>
     Withdraw Available  Balance
</h1>

   <div className='flex flex-col gap-2 '>
   <div>
   <label className='text-white'>Withdraw Options</label>
   </div>
  <div  className='w-full h-12 '>
  <Select
 value={payOptions.find(option => option.value === accountType)} 
 onChange={handlePay}
 options={payOptions}
 className="w-full h-full"
 styles={{
   control: (provided) => ({
     ...provided,
     backgroundColor: 'rgba(0, 0, 0, 0.25)',
     color: 'white',
     border: '1px solid #51087E',
     boxShadow: 'none',
     fontWeight: 600,
     height: '100%',
     display: 'flex',
     alignItems: 'center',
     padding: '0 7px',
     '&:hover': {
       border: '1px solid #51087E',
     },
     outline: 'none',
   }),
   singleValue: (provided) => ({
     ...provided,
     color: 'white',
     fontWeight: 600,
   }),
   option: (provided, state) => ({
     ...provided,
     color: state.isSelected ? 'white' : 'black',
     backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
     '&:hover': {
       backgroundColor: 'rgba(0, 0, 0, 0.3)',
     },
   }),
 
   menu: (provided) => ({
     ...provided,
     zIndex: 100,
   }),
 }}
/>
  </div>
 
   </div>

   {accountType === 1 && loading ? (
<Loader />
) : accountType === 1 && (
<>
 <div className='flex flex-col gap-2'>
   <label className='text-white'>Account Name</label>
   <input
     type="text"
     placeholder="Account Name"
     value={accountName}
     onChange={(e) => setAccountName(e.target.value)}
     className="border border-primary rounded-md h-12 outline-none px-2 text-white"
     style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
     required
   />
 </div>

 <div className='flex flex-col gap-2'>
   <label className='text-white'>Bank Name</label>
   <input
     type="text"
     value={bankName}
     onChange={(e) => setBankName(e.target.value)}
     placeholder="Enter Account Number"
     className="border border-primary rounded-md h-12 outline-none px-2 text-white"
     style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
     required
   />
   {/* <div className='w-full h-12'>
     <Select
       value={nameBank}
       onChange={handleBankChange}
       options={banks}
       className="w-full h-full"
       styles={{
         control: (provided) => ({
           ...provided,
           backgroundColor: 'rgba(0, 0, 0, 0.25)',
           color: 'white',
           border: '1px solid #51087E',
           height: '100%',
           boxShadow: 'none',
           fontWeight: 600,
           display: 'flex',
           alignItems: 'center',
           padding: '0 7px',
           '&:hover': {
             border: '1px solid #51087E',
           },
           outline: 'none',
         }),
         singleValue: (provided) => ({
           ...provided,
           color: 'white',
           fontWeight: 600,
         }),
         option: (provided, state) => ({
           ...provided,
           color: state.isSelected ? 'white' : 'black',
           backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
           '&:hover': {
             backgroundColor: 'rgba(0, 0, 0, 0.3)',
           },
         }),
         menu: (provided) => ({
           ...provided,
           zIndex: 100,
         }),
       }}
     />
   </div> */}
 </div>

 <div className='flex flex-col gap-2'>
   <label className='text-white'>Account Number</label>
   <input
     type="number"
     value={accountNo}
     onChange={(e) => setAccountNo(e.target.value)}
     placeholder="Enter Account Number"
     className="border border-primary rounded-md h-12 outline-none px-2 text-white"
     style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
     required
   />
 </div>

 <div className='flex flex-col gap-2'>
   <label className='text-white'>Bank IBAN</label>
   <input
     type="text"
     value={bankIBAN}
     onChange={(e) => setBankIBAN(e.target.value)}
     placeholder="Enter Bank Iban"
     className="border border-primary rounded-md h-12 outline-none px-2 text-white"
     style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
     required
   />
 </div>

 <div className='flex flex-col gap-2 w-full'>
   <label className='text-white'>Amount</label>
   <div className='flex flex-row w-full h-12 border border-primary rounded-md' style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}>
     <div className='w-[10%] h-full flex flex-col items-center justify-center border-r-2 border-primary'>
       <p className='text-white text-opacity-35'>USD</p>
     </div>
     <div className='w-[90%] h-full'>
       <input
         type="text"
         placeholder="Enter the Amount"
         value={amount}
         onChange={handleAmountChange}
         className="w-full h-full outline-none px-2 text-white"
         style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
         required
       />
     </div>
   </div>
 </div>
</>
)}
{loading && accountType === 2 && <Loader />}
{loading && accountType === 3 && <Loader />}
{loading && accountType === 4 && <Loader />}
{loading && accountType === 5 && <Loader />}

{accountType === 2 && !loading && (
<div className='flex w-full h-20 text-white justify-center items-center'>Mobile Money is not available.</div>
)}
{accountType === 3 && !loading && (
<div className='flex w-full h-20 text-white justify-center items-center'>Paypal is not available.</div>
)}
{accountType === 4 && !loading && (
<p className='flex w-full h-20 text-white justify-center items-center'>Cash App is not available.</p>
)}
{accountType === 5 && !loading && (
<p className='flex w-full h-20 text-white justify-center items-center'>Zelle is not available.</p>
)}

   <div className='flex justify-center'>
   <button 
className='btn-primary text-white py-2 w-40 mt-5' 
onClick={handleSubmit}
disabled={updateLoading || createLoading}
>
{updateLoading || createLoading ? <PulseLoader size={10} color='#fff' /> : 'Submit'}
</button>
   </div>


 </div>
            )
          }
         
        </div>
        </div>
      </div>
  );
}

export default Widthdrawoption;
