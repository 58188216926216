import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import RequestStatus from 'components/global/RequestStatus';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { setCurrentPage } from 'redux/reducers/walletHistoryReducer';


const WalletHistoryTable = ({ isLoading }) => {
    const { currentPage , pages , docsCount , docs } = useSelector(state => state.walletHistory);
    
    return (
        <div>
            <div className='flex items-center justify-between mb-2'>
                <h4 className='text-sm font-semibold text-white'>All Payments</h4>
                <button className="btn-primary py-1.5 text-sm px-4 text-white">
                    Export CSV
                </button>
            </div>
            <div className=" shadow-bg overflow-x-auto rounded-lg">
                <Table className="w-full table-auto overflow-x-auto ">
                    <Thead className="border-b text-sm bg-primary text-pure">
                        <Tr className='bg-primary text-white'>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Date
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Action
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Description
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Amount
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody className='text-sm'>
                    {
                    isLoading 
                    ? 
                        <tr>
                            <td colSpan={5}>
                                <Loader />
                            </td>
                        </tr>
                    :
                    docs?.length > 0 
                    ? 
                        docs?.map((item , i) => (
                            <Tr
                            key={item} 
                            className="bg-pure border-b transition duration-300 ease-in-out"
                            >
                                <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {moment(item?.createdAt).format('DD MMM YYYY')}
                                </Td>
                                <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                    <div className='flex items-center justify-center gap-1'>
                                        {
                                            item?.action === '+'
                                            ? 
                                                <>
                                                    <span className='text-green-500'>Added</span>
                                                </>
                                            : 
                                                <>
                                                    <span className='text-red-500'>Deducted</span>
                                                </>
                                        }
                                    </div>
                                </Td>
                                <Td className=" text-white px-6 py-4 whitespace-nowrap sm:text-center text-center">
                                    {item?.description}
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right text-green-500 font-medium ">
                                {
                                            item?.action === '+'
                                            ? 
                                                <>
                                                    <i className="uil uil-plus text-green-500"></i>
                                                    <span className='text-green-500'>
                                                        {item?.amount}
                                                    </span>
                                                </>
                                            : 
                                                <>
                                                    <i className="uil uil-minus text-red-500"></i>
                                                    <span className='text-red-500'>
                                                        {item?.amount}
                                                    </span>
                                                </>
                                        }
                                </Td>
                            
                                
                            </Tr>
                            ))
                    
                    : 
                        <tr>
                            <td colSpan={5}>
                                <ItemNotFound message='No Wallet History Found.' />
                            </td>
                        </tr>
                        
                    }
                    </Tbody>
                </Table>
                {
                    docs?.length > 0 && 
                    <Pagination 
                    currentPage={currentPage}
                    pageCount={pages}
                    setPage={setCurrentPage}
                    />
                }
            </div>
        </div>
    )
}

export default WalletHistoryTable;