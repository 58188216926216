import Axios from 'config/api';
import {setWithdraw,  addWithdraw, setLoading ,setCreateLoading,updateSingleWithdraw,setUpdateLoading,setError,setSingleWithdraw,setSingleLoading,removeWithdraw,setDeleteLoading } from '../reducers/withdrawReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';



export const fetchWithdraw = () => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs } } } = await Axios.get(`/withdraw/my
`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setWithdraw(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};



export const fetchSingleWithdraw = (id) => async (dispatch, getState) => {
    try {
        dispatch(setSingleLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { doc } } } = await Axios.get(`/withdraw/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setSingleWithdraw(doc));
        dispatch(setSingleLoading(false));
    } catch (err) {
        dispatch(setSingleLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};


export const fetchCreateWithdraw = (data) => async (dispatch , getState) => {
    dispatch(setCreateLoading(true))
    try {
        const { data : { data : { doc, message } } } = await Axios.post('/withdraw ', data , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(addWithdraw(doc));
        toast.success(message);
        dispatch(setCreateLoading(false));
    } catch (err) {
        dispatch(setCreateLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

export const fetchUpdateWithdraw = (id, updatedData) => async (dispatch , getState) => {
    dispatch(setUpdateLoading(true))
    try {
        const { data : { data : { doc, message } } } = await Axios.put(`/withdraw/${id}`, updatedData , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(updateSingleWithdraw(doc));
        toast.success(message);
        dispatch(setUpdateLoading(false));
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

// export const fetchDeleteWithdraw = (id, updatedData) => async (dispatch , getState) => {
//     dispatch(setUpdateLoading(true))
//     try {
//         const { data : { data : { doc, message } } } = await Axios.put(`/withdraw/${id}`, updatedData , {
//             headers : {
//                 Authorization : `Bearer ${getState().auth.user.token}`
//             }
//         } );
//         dispatch(updateSingleWithdraw(doc));
//         toast.success(message);
//         dispatch(setUpdateLoading(false));
//     } catch (err) {
//         dispatch(setUpdateLoading(false));
//         console.log('error' , err);
//         toastError(err)
//     }
// }







