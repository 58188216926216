import SmallClockSvg from 'assets/svgs/SmallClockSvg';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import SettingsLayout from 'components/settings/SettingsLayout';
import CreateTicketPopup from 'components/settings/support/CreateTicketPopup';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setDocs, setDocsCount, setPages, setShowCreateTicketPopup } from 'redux/reducers/ticketReducer';
import fetcher from 'utils/fetcher';
import TimeAgo from 'react-timeago';
import Pagination from 'components/global/pagination';
import { Link } from 'react-router-dom';

const Support = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { 
        docs : tickets , currentPage , pages , docsCount , showCreateTicketPopup 
    } = useSelector(state => state.ticket);

    const queryKey = ['fetch-my-tickets' , currentPage];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/ticket/my` , user);
    }); 

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data])

    return (
        <SettingsLayout>
            <div className="bg-pure rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6">
                <div className='flex items-center justify-between gap-4 border-b pb-4'>
                    <div>
                        <h3 className='font-semibold text-[15px] text-white'>Support</h3>
                        <small className='text-gray-300 sm:block hidden'>Need help? let's create with our agent</small>
                    </div>
                    <div className=''>
                        <button 
                        className="btn-primary py-2 px-4 text-sm text-white"
                        onClick={() => {
                            dispatch(setShowCreateTicketPopup(true))
                        }}
                        >
                            Create Ticket
                        </button>
                    </div>
                </div>
                <div className='flex flex-col gap-4 mt-6'>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        tickets?.length > 0 
                        ?
                        <>
                            {
                                tickets?.map(item => (
                                    <div 
                                    className='flex justify-between sm:flex-row flex-col gap-4 bg-primaryLight rounded-lg py-4 sm:px-4 px-2 border border-gray-400'
                                    key={item?._id}
                                    >
                                        <div className='flex flex-col gap-2'>
                                            <h4 className='font-semibold text-sm text-white'>
                                                {item?.subject}
                                            </h4>
                                            <div className='text-gray-300 text-xs'>
                                                Ticket # {item?._id?.slice(0,10)} Raised 
                                                &nbsp;
                                                <TimeAgo date={item?.createdAt} />
                                            </div>
                                            <p className="text-gray-300 text-sm">
                                                {item?.comment}
                                            </p>
                                            <div className='flex items-center gap-1 text-xs text-green-500 mt-1'>
                                                <SmallClockSvg />
                                                <p>Waiting For Agent Reply (Response expected Within 24 Hours)</p>
                                            </div>
                                            <div className='mt-2'>
                                                <button className="btn-primary py-1 px-4 text-sm text-white">
                                                    View Ticket
                                                </button>
                                            </div>
                                        </div>
                                        <div className='flex justify-end h-fit'>
                                            <Link
                                            to={`/ticket-details/${item?._id}`} 
                                            className="btn-green py-1.5 px-4 text-sm text-white">
                                                Open
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            }
                            <Pagination 
                            pageCount={pages}
                            currentPage={currentPage}
                            setPage={setCurrentPage}

                            />
                        </>
                        :
                            <ItemNotFound message='No Ticket Created yet.' />
                    }
                </div>
            </div>
            {
                showCreateTicketPopup && <CreateTicketPopup />
            }
        </SettingsLayout>
    )
}

export default Support