import { createSlice } from "@reduxjs/toolkit";

const withdrawSlice = createSlice({
    name: 'withdraw', 
    initialState: {
        withdraw: [], 
        singlewithdraw:{},
        singleLoading:false,
        loading: false, 
        createLoading : false , 
        updateLoading:false,
        deleteLoading:false,
        error: null,

    },
    reducers: {
        setWithdraw(state, action) {
            state.withdraw = action.payload; 
        },
        setSingleWithdraw(state, action){
            state.singlewithdraw = action.payload; 
        },
        // removeWithdraw(state , action) {
        //     state.singlewithdraw = state.singlewithdraw.filter(item => item._id !== action.payload);
        // } ,
        addWithdraw(state, action) {
            state.withdraw.unshift(action.payload); 
        },
        updateSingleWithdraw(state, action) {
            const { id, updatedData } = action.payload;
            if (state.withdraw[id]) {
                state.withdraw[id] = {
                    ...state.withdraw[id],
                    ...updatedData,
                };
            }
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        setCreateLoading (state , action ) {
            state.createLoading = action.payload;
        } ,
        setSingleLoading (state , action ) {
            state.singleLoading = action.payload;
        } ,
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload;
        } ,
        // setDeleteLoading (state , action ) {
        //     state.deleteLoading = action.payload;
        // } ,
        setError(state, action) {
            state.error = action.payload;
        }
    },
});
export const { setWithdraw,  addWithdraw, setLoading ,setCreateLoading,updateSingleWithdraw,setUpdateLoading,setSingleWithdraw,setSingleLoading, setError} = withdrawSlice.actions;
export default withdrawSlice.reducer;
