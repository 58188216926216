import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/notificationReducer'
import fetcher from 'utils/fetcher';
import TimeAgo from 'react-timeago'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const NotificationsDrop = () => {
    const dispatch = useDispatch();
    const navigate= useNavigate();
    const { user } = useSelector(state => state.auth);
    const { docs : notifications } = useSelector(state => state.notification);
    const querykey = ['fetch-notifications'];
    const { isLoading, data } = useQuery(querykey, () => {
        return fetcher(`/notification/my`, user);
    });
    console.log('notification',notifications);

    useEffect(() => {
        if (data) {
            const { data: { data: { notifications} } } = data;
            dispatch(setDocs(notifications));  
            
        }
    }, [data]);

    const handleNotificationClick = async (item) => {
        try {
            await fetcher(`/notification/my/${item._id}`, user, 'PUT', { isSeen: true });
            if (item.type === "withdraw") {
                navigate('/widthdraw'); 
            } else if (item.type === "deposit") {
                navigate('/earnings');
            }
        } catch (error) {
            console.error("Error marking notification as seen:", error);
        }
    };
    return (
        <div className='absolute top-10 sm:right-0 -right-12 bg-pure rounded-md sm:w-[300px] w-[270px] text-left text-sm text-black shadow-bg'>
            <h3 className='py-3 px-4 font-semibold text-white'>Notifications</h3>
            <div>
                <h4 className='bg-gray-400 py-3 px-4 font-semibold'>New</h4>
                <div>
                {
                    isLoading 
                    ? 
                        <div className='w-full flex items-center justify-center py-8'>
                            <ClipLoader size={20} color='var(--primary)' />
                        </div>
                    :
                    notifications?.length > 0 
                    ? 
                        notifications?.slice(0,4)?.map(item => (
                            <div className={` text-white py-3 px-4  ${!item.isSeen ? 'bg-[rgba(81,8,126,0.2)]' : ''} hover:bg-primaryLight cursor-pointer border-b`}
                            onClick={() => handleNotificationClick(item)}>
                                <h4 className='text-sm font-semibold capitalize '>
                                    {item?.notifications?.name}
                                </h4>
                                <p>
                                    {item?.description}
                                </p>
                                <div className='flex items-center justify-between gap-2 mt-1 '>
                                <div className='flex flex-col space-y-2'>
                                <button className='font-medium text-gradient '>
                                        View Now
                                    </button>
                                    <div> 
                                    {item.isSeen && (
                            <span className='text-gray-300 text-xs'>Seen</span>
                        )}

                                    </div>
                                </div>
                                   
                                    <TimeAgo date={new Date(item?.createdAt)} />
                                </div>
                            </div>
                        ))
                    :
                        <div className='w-full flex items-center justify-center py-8'>
                            <p className='text-red-500 font-medium'>
                                No Notification Found.
                            </p>
                        </div>
                }
                </div>  
            </div>
            {/* <div>
                <h4 className='bg-gray-300 py-3 px-4 font-semibold'>Early</h4>
                <div>
                    <div className='py-3 px-4 hover:bg-gray-100 cursor-pointer border-b'>
                        <p>
                            This week’s trending Influencers and Categories are in.
                        </p>
                        <div className='flex items-center justify-between gap-2 mt-1 '>
                            <button className='font-medium text-primary'>View Now</button>
                            <p>5 days ago</p>
                        </div>
                    </div>
                    <div className='py-3 px-4 hover:bg-gray-100 cursor-pointer border-b'>
                        <p>
                            This week’s trending Influencers and Categories are in.
                        </p>
                        <div className='flex items-center justify-between gap-2 mt-1 '>
                            <button className='font-medium text-primary'>View Now</button>
                            <p>1 month ago</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='py-3 text-center font-semibold text-gradient '>
                <Link to='/notifications'>
                    View All
                </Link>
            </div>
        </div>
    )
}

export default NotificationsDrop