import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './utilities.css';
import './App.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import "react-accordion-comp/dist/styles.css";

import store from 'redux/store';
import { Provider } from 'react-redux';
import DrawerContextProvider from 'context/DrawerContext';

import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <DrawerContextProvider>
                <Provider store={store}>
                    <Router>
                        <App />
                    </Router>
                </Provider>
            </DrawerContextProvider>
        </GoogleOAuthProvider>
    </QueryClientProvider>
);
