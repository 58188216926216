import { createSlice } from "@reduxjs/toolkit";

const notificationReducer = createSlice({
    name : 'notification' ,
    initialState : {
        docs : [] ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        loading : false ,
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setReadAll(state , action) {
            state.docs = action.payload   
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
    }
});

export const { 
    setDocs , setLoading , setCurrentPage , setPages , setDocsCount,setReadAll
} = notificationReducer.actions;

export default notificationReducer.reducer;