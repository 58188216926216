import React from 'react'
import StatsReportTable from './StatsReportTable'
import { useSelector } from 'react-redux'

const StatsReport = () => {
    
    return (
        <div>
            <div className='flex sm:items-center justify-between gap-4 sm:flex-row flex-col'>
                <h6 className='heading-sm'>Report</h6>
                <div className='flex items-center gap-4'>
                    {/* <div className='bg-pure text-primary font-medium border border-primary py-1 sm:text-sm text-xs flex items-center rounded-lg'>
                        {
                            ['Day' , 'Week' , 'Month'].map(item => (
                                <div 
                                key={item}
                                className='border-r border-r-primary sm:px-3 px-2'
                                >
                                    {item}
                                </div>
                            ))
                        }
                        <div className='sm:px-3 px-2'>None</div>
                    </div> */}
                    <button className="btn-primary py-1.5 sm:px-4 px-3 sm:text-sm text-xs text-white">
                        Export CSV
                    </button>
                </div>
            </div>
            <div className="mt-4">
                <StatsReportTable />
            </div>
        </div>
    )
}

export default StatsReport