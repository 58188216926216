import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
    name : 'setting' ,
    initialState : {
        showSettingsDrawer : false ,
    } ,
    reducers : {
        setShowSettingsDrawer(state , action) {
            state.showSettingsDrawer = action.payload;
        } , 
    
    }
    
});

export const { setShowSettingsDrawer } = settingsSlice.actions;
export default settingsSlice.reducer;


