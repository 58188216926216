import Navbar from 'components/global/navbar';
import Home from 'pages/home';
import MyCompaigns from 'pages/compaigns';
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import CreateCompaign from 'pages/compaigns/CreateCompaign';
import CompaignStats from 'pages/compaigns/CompaignStats';
import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import ReferralLinks from 'pages/referralsLinks';
import Earnings from 'pages/earnings';
import FlipSharedStats from 'pages/flipShared';
import Account from 'pages/settings/Account';
import Payment from 'pages/settings/Payment';
import EditAccount from 'pages/settings/EditAccount';
import Support from 'pages/settings/Support';
import Profile from 'pages/manageAccount/Profile';
import Membership from 'pages/manageAccount/Membership';
import PaymentHistory from 'pages/manageAccount/PaymentHistory';
import Compaigns from 'pages/manageAccount/Compaigns';
import Sidebar from 'components/global/sidebar';
import BlockedPopup from 'components/popups/BlockedPopup';
import { useSelector } from 'react-redux';
import ProtectedRoute from 'ProtectedRoute';
import CompaignClick from 'pages/compaigns/CompaignClick';
import { useApi } from 'config/api';
import { requestForToken, onMessageListener } from './utils/firebase'; 
import FirebaseNotify from 'components/FirebaseNotify';
import Notifications from 'pages/notifications';
import TicketDetails from 'pages/settings/TicketDetails';
import Pay from 'pages/paymentgateway/index';
import Widthdraw from 'pages/widthdraw';
import Sucess from 'pages/paymentgateway/sucess';
import Cancel from 'pages/paymentgateway/cancel';
import  PaymentCallback from 'pages/paymentcallback';
import { io } from 'socket.io-client';

const App = () => {
    const api = useApi();
    const location = useLocation();
    const { showBlockedPopup,user } = useSelector(state => state.auth);
    const UserId = user?.wallet?.user;
    const [socket, setSocket] = useState(null);  
    console.log("soket",socket);

    useEffect(() => {

        const socket = io(`https://reflip.raabtastore.com?userId=${UserId}`, {
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            timeout: 5000,
        });
    
        socket.on('connect', () => {
            console.log('Socket.IO connected');
        });
        socket.on('disconnect', (reason) => {
            console.log('Socket.IO disconnected:', reason);
        });
        socket.on('pong', (data) => {
            console.log('Received pong:', data.timestamp);
        });

        
        const intervalId = setInterval(() => {
            if (socket.connected) {
                socket.emit('ping', { timestamp: Date.now() });
                console.log('Ping sent');
            }
        }, 30000);
    
        return () => {
            clearInterval(intervalId);
            socket.disconnect();
            console.log('Socket.IO disconnected on component unmount');
        };
    }, [UserId]);



    useEffect(() => {
        window.scrollTo(0,0)
    }, [location.pathname]);

    const [notification, setNotification] = useState({ title: "", body: "" });
    const notify = () => toast.info(<ToastDisplay />);
    function ToastDisplay() {
        return (
            <Link to='/notifications'>
                <p>
                    <b>{notification?.title}</b>
                </p>
                <p>{notification?.body}</p>
            </Link>
        );
    }

    useEffect(() => {
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    onMessageListener()
        .then((payload) => {
            console.log({ payload });
            setNotification({ title: payload?.data?.title, body: payload?.data?.body });
    })
    .catch((err) => console.log("failed: ", err));


    const [notificationPermission, setNotificationPermission] = useState('default');
    useEffect(() => {
        const requestNotificationPermission = () => {
            if ('Notification' in window) {
                if (Notification.permission === 'granted') {
                    setNotificationPermission('granted');
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then(permission => {
                    setNotificationPermission(permission);
                    });
                }
            }
        };
        requestNotificationPermission();
    }, [])

        
    return (
        <div className="relative">
            { showBlockedPopup ? <BlockedPopup /> : ''}
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={2000}
                closeOnClick
                pauseOnHover
                hideProgressBar
            />
            <Sidebar />
            <Routes>
                <Route path='/' element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                } />
                <Route path='/dashboard' element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                } />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/compaign/my' element={<MyCompaigns />} />
                <Route path='/flip-shared' element={<FlipSharedStats />} />
                <Route path='/compaign/create-new' element={<CreateCompaign />} />
                <Route path='/compaign/stats' element={<CompaignStats />} />
                <Route path='/compaign/click/:id' element={<CompaignClick/>} />
                <Route path='/referral-links' element={<ReferralLinks />} />
                <Route path='/earnings' element={<Earnings />} />
                <Route path='/settings/account' element={<Account />} />
                <Route path='/settings/account/edit' element={<EditAccount />} />
                <Route path='/settings/payment' element={<Payment />} />
                {/* <Route path='/settings/notifications' element={<Notifications />} /> */}
                <Route path='/settings/support' element={<Support />} />
                <Route path='/manage-account/profile' element={<Profile />} />
                <Route path='/ticket-details/:id' element={<TicketDetails />} />
                {/* <Route 
                path='/manage-account/membership' 
                element={<Membership />} 
                /> */}
                <Route 
                path='/manage-account/payment-history' 
                element={<PaymentHistory />} 
                />
                <Route 
                path='/manage-account/compaigns' 
                element={<Compaigns />} 
                />
                <Route path='/notifications' element={<Notifications />} />
                <Route 
                path='/firebase-notify' 
                element={<FirebaseNotify />} 
                />
            <Route path='/widthdraw' element={<Widthdraw/>}/>
             <Route path='/pay' element={<Pay/>}/>
             <Route path='/cancel' element={<Cancel/>}/>
             <Route path='/success' element={<Sucess/>}/>
             <Route path='/paymentcallback' element={<PaymentCallback/>}/>

            </Routes>
        </div>
    )
}

export default App