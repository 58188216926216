import React from 'react'

const LogoPrimary = ({ w = 130 , h = 54 }) => {
    return (
        <svg width={w} height={h} viewBox="0 0 212 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_131_22135)">
<path d="M71.2344 40.4359L63.1754 26.5124H62.1616V40.4359H53.4482V4.64551H67.577C70.3617 4.64551 72.7486 5.15882 74.7377 6.1726C76.7268 7.18638 78.2153 8.53382 79.2291 10.2021C80.2301 11.8703 80.7306 13.6669 80.7306 15.6046C80.7306 17.6835 80.1018 19.6983 78.8441 21.6745C77.5865 23.6507 75.5333 25.0367 72.6716 25.858L81.4364 40.4359H71.2344ZM62.1616 19.8908H67.5642C68.886 19.8908 69.9383 19.5058 70.6954 18.723C71.4525 17.9402 71.8375 16.9007 71.8375 15.6175C71.8375 14.3342 71.4525 13.3204 70.6697 12.512C69.8869 11.6907 68.8475 11.2928 67.5642 11.2928H62.1616V19.9036V19.8908Z" fill="#51087E"/>
<path d="M113.916 29.066H94.3331C94.5 30.3236 95.0646 31.3503 96.0142 32.1459C96.9638 32.9415 98.1573 33.3393 99.5817 33.3393C100.672 33.3393 101.481 33.1725 102.033 32.8517C102.572 32.5309 103.149 31.9919 103.765 31.2476H113.197C112.376 34.0965 110.746 36.4063 108.308 38.1516C105.857 39.8968 102.957 40.7823 99.5945 40.7823C96.874 40.7823 94.4358 40.192 92.2799 38.9985C90.124 37.8051 88.4429 36.0984 87.2367 33.8783C86.0304 31.6582 85.4272 29.0789 85.4272 26.153C85.4272 23.2272 86.0304 20.6735 87.2367 18.4791C88.4429 16.2847 90.124 14.6036 92.2542 13.4358C94.3973 12.268 96.8227 11.6777 99.5432 11.6777C102.264 11.6777 104.779 12.2424 106.986 13.3845C109.193 14.5266 110.939 16.1948 112.209 18.4021C113.48 20.6093 114.121 23.24 114.121 26.307C114.121 27.2951 114.057 28.2062 113.916 29.066ZM105.048 23.6121C104.907 22.2904 104.33 21.2124 103.316 20.3783C102.302 19.5442 101.032 19.1335 99.5432 19.1335C98.2471 19.1335 97.1307 19.557 96.181 20.3783C95.2314 21.2124 94.6283 22.2904 94.3973 23.6121H105.048Z" fill="#51087E"/>
<path d="M143.02 4.64551V11.28H128.442V18.9796H139.35V25.6013H128.442V40.4359H119.729V4.64551H143.033H143.02Z" fill="#51087E"/>
<path d="M157.765 2.70752V40.4357H149.052V2.70752H157.765Z" fill="#51087E"/>
<path d="M166.119 1.47576C167.107 0.487643 168.314 0 169.738 0C171.163 0 172.369 0.487643 173.357 1.47576C174.345 2.46388 174.833 3.68298 174.833 5.14591C174.833 6.60884 174.345 7.77662 173.357 8.76473C172.369 9.75285 171.163 10.2405 169.738 10.2405C168.314 10.2405 167.107 9.75285 166.119 8.76473C165.131 7.77662 164.644 6.57034 164.644 5.14591C164.644 3.72148 165.131 2.46388 166.119 1.47576ZM174.127 11.9729V40.423H165.414V11.9857H174.127V11.9729Z" fill="#51087E"/>
<path d="M205.362 13.5385C207.351 14.7832 208.917 16.5028 210.084 18.7101C211.239 20.9173 211.817 23.4325 211.817 26.2557C211.817 29.0789 211.239 31.5941 210.084 33.8013C208.929 36.0085 207.351 37.7281 205.362 38.9472C203.373 40.1663 201.204 40.7823 198.856 40.7823C195.314 40.7823 192.529 39.2809 190.489 36.2909V53.9871H181.775V11.9857H190.489V15.7072C192.427 13.0252 195.211 11.6777 198.856 11.6777C201.204 11.6777 203.373 12.2937 205.362 13.5385ZM193.71 20.019C192.76 20.5451 191.99 21.3279 191.387 22.3674C190.797 23.4068 190.502 24.6901 190.502 26.2172C190.502 27.7443 190.797 29.0404 191.387 30.0927C191.977 31.1449 192.76 31.9406 193.735 32.4667C194.698 32.9928 195.699 33.2623 196.713 33.2623C197.726 33.2623 198.766 33.0057 199.716 32.4667C200.665 31.9406 201.435 31.1578 202.038 30.1183C202.629 29.0789 202.937 27.7956 202.937 26.2685C202.937 24.7414 202.641 23.4453 202.038 22.393C201.448 21.3408 200.665 20.5451 199.69 20.019C198.715 19.4928 197.726 19.2234 196.713 19.2234C195.699 19.2234 194.659 19.4928 193.71 20.019Z" fill="#51087E"/>
<path d="M9.38046 12.9352C8.87999 12.4475 8.08436 12.4603 7.58389 12.9352C6.46744 14.0388 4.73503 15.8867 4.5297 16.1048C0.294913 20.5706 -1.27068 27.6928 1.12904 33.4161C2.70746 37.1761 5.9798 39.8838 8.80299 42.707C11.331 45.2351 13.8462 47.7503 16.3743 50.2783L17.9399 51.8439C18.4403 52.3444 19.2616 52.3444 19.775 51.8439L23.1371 48.4817C23.6376 47.9813 23.6376 47.16 23.1371 46.6467L11.2284 34.7379C9.17514 32.6847 7.53256 30.3363 7.51972 27.2693C7.51972 26.0373 7.81488 24.8439 8.35385 23.766C8.73883 22.9832 9.80394 22.842 10.4199 23.458L13.1789 26.217C13.6794 26.7175 14.5007 26.7175 15.014 26.217L18.1067 23.1243C18.62 22.611 18.62 21.7769 18.0939 21.2764C16.0663 19.3258 11.3182 14.7831 9.38046 12.9352Z" fill="#51087E"/>
<path d="M28.2062 41.2827C28.6939 41.7832 29.4895 41.796 30.0028 41.3212C31.1578 40.2433 32.9287 38.4467 33.134 38.2285C37.4714 33.8783 39.2167 26.8074 36.971 21.0199C35.4952 17.2214 32.287 14.4239 29.528 11.5365C27.077 8.94433 24.6131 6.36496 22.162 3.77275L20.6349 2.16867C20.1473 1.65536 19.326 1.62969 18.7999 2.11734L15.3479 5.38967C14.8346 5.87732 14.8089 6.69861 15.2965 7.22475L26.8973 19.4286C28.8992 21.5332 30.4776 23.9201 30.4135 26.9871C30.3878 28.219 30.0541 29.3997 29.4895 30.4648C29.0789 31.2347 28.0266 31.3502 27.4234 30.7214L24.7286 27.8854C24.2409 27.3721 23.4196 27.3464 22.8935 27.8341L19.7238 30.8498C19.1977 31.3502 19.1848 32.1843 19.6982 32.6977C21.6744 34.6996 26.307 39.3578 28.1934 41.2699L28.2062 41.2827Z" fill="#51087E"/>
</g>
<defs>
<clipPath id="clip0_131_22135">
<rect width="211.804" height="54" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}

export default LogoPrimary