import StatsFilter from 'components/compaigns/StatsFilter'
import StatsInfo from 'components/compaigns/StatsInfo'
import StatsReport from 'components/compaigns/StatsReport'
import FlipSharedTable from 'components/flipShared/FlipSharedTable'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { setCurrentPage, setDocs, setDocsCount, setFrom, setPages, setTo } from 'redux/reducers/flipSharedReducer'
import fetcher from 'utils/fetcher'

const FlipSharedStats = () => {
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , pages , docsCount , from , to } = useSelector(state => state.flipShare);

    const queryKey = ['fetch-my-flip-shares' , currentPage];
    const { isLoading , data , refetch , isRefetching } = useQuery(queryKey , () => {
        return fetcher(`/flip-share/my?page=${currentPage}&from=${from}&to=${to}` , user);
    })

    useEffect(() => {
        if (data) {
            const { data : { data : { docs , docsCount , page , pages } } } = data;
            dispatch(setDocsCount(docsCount));
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
        }
    }, [data]);

    const searchHandler = () => {
        refetch();
    }

    return (
        <Layout>
            <div 
            className=" sm:p-4 px-2 py-4 min-h-screen mb-6"
            >
                <div className="flex items-center gap-2 border-b pb-4">
                    <h3 className='font-semibold text-white'>Flip Shared Stats</h3>
                </div>
                <div className="mt-6">
                    <div className='shadow-bg py-4 sm:px-4 px-2 bg-pure'>
                        <div className='flex items-center gap-4 sm:flex-row flex-col'>
                            <div className="input-group ">
                                <label className='text-white'>From</label>
                                <input 
                                type="date" 
                                placeholder='Select From Date'
                                onChange={(e) => {
                                    dispatch(setFrom(e.target.value));
                                }}
                                value={from}
                                />
                            </div>
                            <div className="input-group">
                                <label>To</label>
                                <input 
                                type="date" 
                                placeholder='Select To Date'
                                onChange={(e) => {
                                    dispatch(setTo(e.target.value));
                                }}
                                value={to}
                                />
                            </div>
                        </div>
                        <div className="mt-4 flex items-end justify-end">
                            <button 
                            className="btn-primary py-2 px-12 text-white"
                            disabled={isRefetching}
                            onClick={searchHandler}
                            >
                                {
                                    isRefetching
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    :
                                        'Search'
                                }
                            </button>
                        </div>
                    </div>
                                
                    <div className="mt-10">
                        <div className='flex sm:items-center justify-between gap-4 sm:flex-row flex-col'>
                            <h6 className='heading-sm'>Report</h6>
                            <div className='font-medium text-white'>
                                Total Flip Shares : {docsCount}
                            </div>
                        </div>
                        <div className='mt-4'>
                            {
                                isLoading 
                                ? 
                                    <Loader />
                                :
                                    <FlipSharedTable /> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    ) 
}

export default FlipSharedStats;