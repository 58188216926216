import React from 'react'

const PaymentSvg = ({ size = 20 }) => {
    return (
        <div>
            <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 5.83317V6.24984C15.7499 7.49984 15.1666 8.58317 14.4166 9.33317C13.4999 10.2498 12.2499 10.8332 10.8333 10.8332H8.24993C7.83326 10.8332 7.49993 11.1665 7.4166 11.5832L6.74993 15.9998C6.6666 16.3332 6.49993 16.5832 6.1666 16.6665H4.33326C3.83326 16.6665 3.4166 16.2498 3.49993 15.6665L5.49993 3.74984C5.6666 2.58317 6.74993 1.6665 7.9166 1.6665H11.6666C13.9999 1.6665 15.8333 3.49984 15.8333 5.83317Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"/>
<path d="M17.4999 9.16667C17.4999 10.3333 16.9999 11.3333 16.2499 12.0833C15.4999 12.8333 14.4999 13.3333 13.3333 13.3333H11.5833C11.1666 13.3333 10.8333 13.5833 10.7499 14L10.1666 17.5833C10.0833 18 9.74993 18.25 9.33326 18.25H6.83326C6.33326 18.25 5.9166 17.75 5.99993 17.25L6.1666 16.6667C6.49993 16.5833 6.74993 16.3333 6.74993 16L7.4166 11.5833C7.49993 11.1667 7.83326 10.8333 8.24993 10.8333H10.8333C12.2499 10.8333 13.4999 10.25 14.3333 9.33333C15.1666 8.5 15.6666 7.41667 15.7499 6.25C16.8333 6.83333 17.4999 7.91667 17.4999 9.16667Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"/>
</svg>

        </div>
    )
}

export default PaymentSvg