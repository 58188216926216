import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
  } from "react-share";


  import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";

  import { useEffect, useRef, useState } from 'react';
  import './styles.css';
  import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from "react-redux";
import { setShowSharePopup } from "redux/reducers/compaignReducer";
import Axios from "config/api";
import toastError from "utils/toastError";
  
  
const SharePopup = () => {
    const { user } = useSelector(state => state.auth);
    const { compaign } = useSelector(state => state.compaign);

    const url = `${window.location.origin}/compaign/click/${compaign?._id}?shared=${true}&sharedBy=${user?._id}`;
    const popupRef = useRef(null); 
    const dispatch = useDispatch();
    
    const shareCountHandler = async () => {
        try {
            await Axios(`/compaign/share/${compaign?._id}` , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
        } catch (error) {
            toastError(error);
        }
    }
              
    return (
        <div className='fixed top-0 left-0 w-full min-h-screen py-10 px-4 flex items-center justify-center bg-black bg-opacity-50 z-[9999]'>
            <div
            ref={popupRef} 
            className='popup bg-white sm:w-[600px] w-full sm:p-4 p-4 rounded-lg relative px-6'>
                <div 
                className='absolute top-1 right-3 text-2xl cursor-pointer'
                onClick={() => dispatch(setShowSharePopup(false))}
                >
                    <i className="uil uil-times"></i>
                </div>
                <center>
                    <h1 className="font-semibold text-lg ">Share Compaign</h1>
                </center>
                <div className="mt-10 flex flex-wrap items-center justify-center gap-6"> 

                    {/* <div className='flex items-center flex-col gap-1'>
                        <EmailShareButton 
                        subject={compaign?.name}
                        body={compaign?.description}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <EmailIcon round={true} size={35} />
                        </EmailShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Email
                        </p>
                    </div> */}

                    <div className='flex items-center flex-col gap-1'>
                        <TwitterShareButton
                        title={compaign?.name}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <TwitterIcon round={true} size={35} />
                        </TwitterShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Twitter
                        </p>
                    </div>

                    <div className='flex items-center flex-col gap-1'>
                        <TelegramShareButton 
                        title={compaign?.name}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <TelegramIcon round={true} size={35} />
                        </TelegramShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Telegram
                        </p>
                    </div>
                    
                    <div className='flex items-center flex-col gap-1'>
                        <WhatsappShareButton 
                        title={compaign?.name}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <WhatsappIcon round={true} size={35} />
                        </WhatsappShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Whatsapp
                        </p>
                    </div>
                    
                    <div className='flex items-center flex-col gap-1'>
                        <FacebookShareButton
                        quote={compaign?.name}
                        hashtag="#test"
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <FacebookIcon round={true} size={35} />
                        </FacebookShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Facebook
                        </p>
                    </div>

                    <div className='flex items-center flex-col gap-1'>
                        <LinkedinShareButton
                        title={compaign?.name}
                        summary={compaign?.description}
                        // source={compaign?.url}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <LinkedinIcon round={true} size={35} />
                        </LinkedinShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            LinkedIn
                        </p>
                    </div>

                    <div className='flex items-center flex-col gap-1'>
                        <LineShareButton 
                        title={compaign?.name}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <LineIcon round={true} size={35} />
                        </LineShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Line
                        </p>
                    </div>
                        
                    <div className='flex items-center flex-col gap-1'>
                        <PinterestShareButton 
                        title={compaign?.name}
                        description={compaign?.description}
                        url={url}
                        onClick={shareCountHandler}
                        >
                            <PinterestIcon round={true} size={35} />
                        </PinterestShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Pinterest
                        </p>
                    </div>

                    <div className='flex items-center flex-col gap-1'>
                        <RedditShareButton 
                        title={compaign?.name}
                        url={url}
                        >
                            <RedditIcon round={true} size={35} />
                        </RedditShareButton>
                        <p className="text-sm font-medium text-gray-600">
                            Reddit
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ) 
  }
  
  export default SharePopup