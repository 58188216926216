import SettingsLayout from 'components/settings/SettingsLayout';
import AccountInformation from 'components/settings/account/AccountInformation';
import ProfileInfo from 'components/settings/account/ProfileInfo';
import UpdatePasswordForm from 'components/settings/account/UpdatePasswordForm';
import React from 'react'

const Account = () => {
    return (
        <SettingsLayout>
            <div className="bg-pure rounded-lg sm:p-4 px-2 py-4 h-full mb-6">
                <ProfileInfo />
            </div>
            <div className="bg-pure rounded-lg sm:p-4 px-2 py-4 h-full mb-6">
                <UpdatePasswordForm />
            </div>
        </SettingsLayout>
    )
}

export default Account;