import Axios from 'config/api';
import { setLoading , setDocs } from 'redux/reducers/messageReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const url = '/message';

export const message_getDocs = (ticketId) => async (dispatch , getState) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { docs } } } = await Axios(`${url}/${ticketId}` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(setDocs(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}