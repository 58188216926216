import NameInput from 'components/global/NameInput';
import PasswordInput from 'components/global/PasswordInput';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { updatePassword } from 'redux/actions/authActions';

const initialState = {
    currentPassword : '' , 
    newPassword : '' ,
    confirmPassword : ''
};

const UpdatePasswordForm = () => {
    const dispatch = useDispatch();
    const { user , passwordLoading } = useSelector(state => state.auth);

    const [data , setData] = useState(initialState);

    const submitHandler = async e => {
        e.preventDefault();
        dispatch(updatePassword(data , setData , initialState));
    }

    return (
        <div className=''>
            <div className='border-b py-4 gap-4'>
                <h3 className='text[15px] font-semibold text-white'>
                    Update Password
                </h3>
                <small className='text-gray-300'>
                    Manage your password details to keep your account secure.
                </small>
            </div>
            <form 
            className='mt-4'
            onSubmit={submitHandler}
            >
                <div className='input-group'>
                    <PasswordInput
                    label='Current Password'
                    data={data}
                    setData={setData}
                    placeholder='Enter your current password'
                    name='currentPassword'
                    />
                </div>
                <div className='flex md:flex-row flex-col gap-4 items-center mt-4 input-group'>
                    <PasswordInput
                    label='new Password'
                    data={data}
                    setData={setData}
                    placeholder='Enter new password'
                    name='newPassword'
                    />
                    <PasswordInput
                    label='Confirm Password'
                    data={data}
                    setData={setData}
                    placeholder='Confirm password'
                    name='confirmPassword'
                    />
                </div>
                <div className='mt-6 flex justify-end'>
                    <button 
                    className="btn-primary py-2 px-12 text-white"
                    disabled={passwordLoading || !data.currentPassword || !data.newPassword || !data.confirmPassword}
                    >
                        {
                            passwordLoading
                            ?
                                <ClipLoader size={20} color='white' />
                            : 
                                'Save'
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UpdatePasswordForm