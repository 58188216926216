import AngleDownSvg from "assets/svgs/AngleDownSvg";
import AngleTopSvg from "assets/svgs/AngleTopSvg";
import { useEffect, useState } from "react";
import Accordion from "react-accordion-comp";
import { Link } from "react-router-dom";

const Start = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <div className="bg-pure rounded-lg text-white">
            <div className="flex items-center justify-between gap-4 border-b py-4 sm:px-4 px-2 cursor-pointer"
            onClick={() => setShow(!show)}
            >
                <h6 className="heading-sm ">Start Here</h6>
                <div 
                className="cursor-pointer"
                >
                    {
                        show
                        ? 
                            <AngleTopSvg size={25} />
                        : 
                            <AngleDownSvg size={25} />
                    }
                </div>
            </div>
            <Accordion isOpen={show}>
                <ul className="flex flex-col gap-2 font-medium text-sm py-4 sm:px-4 px-2">
                    <li>
                        <Link to='/compaign/my'>
                            Flip Compaigns
                        </Link>
                    </li>
                    <li>
                        <Link to='/flip-shared'>
                            Flip Shared
                        </Link>
                    </li>
                    <li>
                        <Link to='/referral-links'>
                            Flip Referral Link
                        </Link>
                    </li>
                    <li>
                        <Link to='/compaign/stats'>
                            Analytics
                        </Link>
                    </li>
                    <li>
                        <Link to='/settings/account'>
                            Settings
                        </Link>
                    </li>
                </ul>
            </Accordion>
        </div>
    )
}

export default Start