import Axios from 'config/api';
import { setLoading , setDocDetails , removeDoc , addDoc, setCreateLoading, setDeleteLoading } from 'redux/reducers/compaignReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const url = '/compaign';

export const compaign_createDoc = (data, navigate) => async (dispatch, getState) => {
    dispatch(setCreateLoading(true));
    try {
        const { data: { data: { doc, message } } } = await Axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${getState().auth.user.token}`
            }
        });
        dispatch(addDoc(doc));
        toast.success(message);
        navigate('/dashboard');
    } catch (err) {
        dispatch(setCreateLoading(false));
        console.log('error', err);
        const errorMessage = err.response?.data?.data?.message || 'An error occurred';
        if (errorMessage === "You have insufficient balance in your wallet to create this compaign.") {
            return { type: 'INSUFFICIENT_BALANCE' }; 
        }
        toastError(err); 
    } finally {
        dispatch(setCreateLoading(false));
    }
};



export const compaign_getDetails = (itemId) => async (dispatch , getState) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { doc } } } = await Axios(`${url}/${itemId}` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        } );
        dispatch(setDocDetails(doc));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}

export const compaign_delete = (itemId) => async (dispatch , getState) => {
    dispatch(setDeleteLoading(true))
    try {
        const { data : { data : { message } } } = await Axios.delete(`${url}/${itemId}` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        });
        toast.success(message)
        dispatch(removeDoc(itemId));
        dispatch(setDeleteLoading(false));
    } catch (err) {
        dispatch(setDeleteLoading(false));
        console.log('error' , err);
        toastError(err)
    }
}
