import SettingsLayout from 'components/settings/SettingsLayout';
import EditAccountInfo from 'components/settings/account/EditAccountInfo';


const EditAccount = () => {
    return (
        <SettingsLayout>
            <div className="bg-white bg-opacity-60 rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6">
                <EditAccountInfo />
            </div>
        </SettingsLayout>
    )
}

export default EditAccount;