import React, { useEffect,useState } from 'react'
import Select from 'react-select'
import { State } from 'country-state-city'

const StateSelector = ({countryCode,setState}) => {
    const [options,setOptions]=useState([]);
     useEffect(()=>{
        if (!countryCode) return;
        const  states = State.getStatesOfCountry(countryCode);
        const stateOptions = states.map(state => ({
            value: state.isoCode,
            label: state.name,
        }));
        setOptions(stateOptions);
     },[countryCode])
     const changeHandler = (selectedState) => {
        setState(selectedState.value); 
    };
   return <Select 
    options={options} 
    onChange={changeHandler} 
    className='outline-none w-full'
    inputId='select-box-input'
    placeholder='Select State'
    />
}

export default StateSelector
