import React from 'react'

const Textarea = ({ 
    label , type = 'text' , placeholder = '' , name = '' , setData , data , optional , ...props
}) => {

    const handleChange = (e) => {
        const { name , value } = e.target;
        setData(prev => ({...prev , [name] : value }))
    }
    return (
        <div className='flex flex-col gap-1.5 flex-1 w-full'>
            <label className='font-semibold  text-white '>
                {label} {optional && <span className=' font-medium text-sm'>(Optional)</span>}
            </label>
            <textarea 
            type={type}
            placeholder={placeholder}
            className='input w-full resize-none'
            rows={5}
            name={name}
            value={data[name]}
            onChange={handleChange}
            {...props}
            />
        </div>
    )
}

export default Textarea