import StatsFilter from 'components/compaigns/StatsFilter'
import StatsInfo from 'components/compaigns/StatsInfo'
import StatsReport from 'components/compaigns/StatsReport'
import Loader from 'components/global/Loader'
import ManageAccountLayout from 'components/manageAccount/ManageAccountLayout'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/compaignReducer'
import fetcher from 'utils/fetcher'

const Compaigns = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { currentPage , from , to , keyword  } = useSelector(state => state.compaign);

    const compaignQueryKey = ['fetch-my-compaigns' , currentPage  ];
    const { isLoading , data  } = useQuery(compaignQueryKey , () => {
        return fetcher(`/compaign/my?keyword=${keyword}&from=${from}&to=${to}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data])


    return (
        <ManageAccountLayout>
            <div className='shadow-bg px-4 mt-6 pb-4'>
                <div className='border-b py-4'>
                    <h3 className='font-semibold text-[15px] text-white'>
                        Add Compaings
                    </h3>
                </div>
                <div className='mt-6'>
                    <StatsFilter />
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    :
                        <>
                            <div className='mt-6'>
                                <StatsInfo />
                            </div>
                            <div className='mt-10'>
                                <StatsReport />
                            </div>
                        </>
                }
            </div>
        </ManageAccountLayout>
    )
}

export default Compaigns