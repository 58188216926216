import React, { useEffect,useState } from 'react'
import Select from 'react-select'
import { City } from 'country-state-city'

const CitySelector = ({countryCode,stateCode,setCity}) => {
    const [options,setOptions]=useState([]);

    useEffect(()=>{
        if (!countryCode || !stateCode) return;
        const cities = City.getCitiesOfState(countryCode, stateCode);
           const cityoption=cities.map(city=>({
            value: city.name,
            label: city.name,
           }))
           setOptions(cityoption);
    },[[countryCode, stateCode]])


    const changeHandler = (selectedCity) => {
        setCity(selectedCity.value); 
    };


  return (
    <Select
            options={options}
            onChange={changeHandler}
            className='outline-none w-full'
            placeholder='Select City'
               inputId='select-box-input'
            isDisabled={!stateCode} 
        />
  )
}

export default CitySelector
