import AngleDownSvg from "assets/svgs/AngleDownSvg";
import AngleTopSvg from "assets/svgs/AngleTopSvg";
import { useEffect, useState } from "react";
import Accordion from "react-accordion-comp";
import { Link } from "react-router-dom";

const FlipSharedStats = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <div className="bg-pure rounded-lg">
            <div className="flex items-center justify-between gap-4 border-b py-4 sm:px-4 px-2 cursor-pointer"
            onClick={() => setShow(!show)}
            >
                <h4 className="heading-sm ">
                   Your Flip Shared Stats
                </h4>
                <div 
                className="cursor-pointer"
                >
                    {
                        show
                        ? 
                            <AngleTopSvg size={25} />
                        : 
                            <AngleDownSvg size={25} />
                    }
                </div>
            </div>
            <Accordion isOpen={show}>
                <div className="py-3 sm:px-4 px-2 text-sm flex flex-col gap-4">
                    <div className="flex items-center justify-between gap-4 text-primary">
                        <h6 className="font-medium text-gray-300">
                            Total Flip Links Shared
                        </h6>
                        <p className="font-semibold text-white ">
                            9.6k
                        </p>
                    </div>
                    <select
                    className="select-box py-1.5 w-full border-primary"
                    >
                        {
                            ['Week' , 'Month' , '6 Month' , 'Year'].map(item => (
                                <option
                                key={item}
                                value={item}
                                >
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                    <div className="flex items-center justify-between gap-4">
                        <div className="text-[15px]">
                            <p className="font-semibold text-gradient">5,000</p>
                            <p className="font-semibold my-1 text-white">All Shared Clicks</p>
                            <p className="text-[13px] text-gray-300">vs pre month &nbsp; 
                                <span className="text-green-500">10.0%</span>
                            </p>
                        </div>
                        <div className="text-[15px]">
                            <p className="font-semibold text-gradient">1,000</p>
                            <p className="font-semibold my-1 text-white">Total Conversions</p>
                            <p className="text-[13px] text-gray-300">vs pre month &nbsp; 
                                <span className="text-red-500">-10.0%</span>
                            </p>
                        </div>
                    </div>
                    <div className="text-[15px]">
                        <p className="font-semibold text-gradient">2,000</p>
                        <p className="font-semibold my-1 text-white">Total Points</p>
                        <p className="text-[13px] text-gray-300">vs pre month &nbsp; 
                            <span className="text-green-500">10.0%</span>
                        </p>
                    </div>
                    <div className="text-[15px]">
                        <p className="font-semibold text-gradient">USD 200.00</p>
                        <p className="font-semibold my-1 text-white">
                            Estimated Earnings
                        </p>
                        <p className="text-[13px] text-gray-300">vs pre month &nbsp; 
                            <span className="text-red-500">-10.0%</span>
                        </p>
                    </div>
                </div>
            </Accordion>
        </div>
    )
}

export default FlipSharedStats