import Axios from 'config/api';
import { setLoading, setDeposits, setError } from 'redux/reducers/depositReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const url = '/user/my-deposit';

export const deposit_create = (depositData) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const { data: { data: { data,message } } } = await Axios.post(url, depositData, {
            headers: {
                Authorization: `Bearer ${getState().auth.user.token}`,
            },
        });
        dispatch(setDeposits(data));
        toast.success(message);
    } catch (err) {
        console.error('Error:', err);
        dispatch(setError(err.response?.data?.message || 'An error occurred'));
        toastError(err);
    } finally {
        dispatch(setLoading(false));
    }
};
