import React from 'react'

const AngleTopSvg = ({ size = 24 }) => {
    return (
        <svg fill="#fff" width={size} height={size} viewBox="-8.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.84 20.040c-0.2 0-0.44-0.080-0.6-0.24-0.32-0.32-0.32-0.84 0-1.2l6.44-6.44c0.32-0.32 0.88-0.32 1.2 0l6.44 6.44c0.32 0.32 0.32 0.84 0 1.2-0.32 0.32-0.84 0.32-1.2 0l-5.84-5.84-5.84 5.84c-0.16 0.16-0.4 0.24-0.6 0.24z"></path>
        </svg>
    )
}

export default AngleTopSvg