import { createSlice } from "@reduxjs/toolkit";

const teamSlice = createSlice({
    name : 'team' ,
    initialState : {
        docs : [] ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        duration : '' ,
        keyword : '' ,
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
        setKeyword (state , action) {
            state.keyword = action.payload;
        } ,
        setDuration (state , action) {
            state.duration = action.payload;
        } ,
    
    }
});

export const { 
    setDocs , setLoading , setCurrentPage , setPages , setDocsCount , setKeyword , setDuration 
} = teamSlice.actions;

export default teamSlice.reducer;