import NameInput from 'components/global/NameInput';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setFrom, setTo } from 'redux/reducers/walletHistoryReducer';

const PaymentFilters = ({ refetch }) => {
    const dispatch = useDispatch();
    const { from , to } = useSelector(state => state.walletHistory);

    const resetHandler = () => {
        dispatch(setFrom(''));
        dispatch(setTo(''));
        refetch();
    }

    return (
        <div>
            <div className='bg-pure rounded-md p-4'>
                <div className='grid gap-4 sm:grid-cols-2 grid-cols-1'>
                    <div className="input-group">
                        <label>From</label>
                        <input 
                        type="date" 
                        placeholder='Select Start Date'
                        onChange={(e) => dispatch(setFrom(e.target.value))}
                        />
                    </div>
                    <div className="input-group">
                        <label>From</label>
                        <input 
                        type="date" 
                        placeholder='Select End Date'
                        onChange={(e) => dispatch(setTo(e.target.value))}
                        />
                    </div>
                    {/* <div className="flex flex-1 flex-col gap-1.5 w-full">
                        <label 
                        className="font-semibold text-dark"
                        >
                            Status
                        </label>
                        <select
                        className='select-box py-3'
                        >
                            {
                                ['All' , 'Processing' , 'Paid' , 'Failed'].map(item => (
                                    <option key={item}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                    </div> */}
                </div>
       
                <div className='flex items-end gap-4 justify-end mt-6 sm:text-sm text-xs'>
                    <button 
                    className="btn-secondary py-2 px-4 text-white"
                    onClick={resetHandler}
                    >
                        Reset
                    </button>
                    <button className="btn-primary py-2 px-4 border text-white">
                        Search
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PaymentFilters