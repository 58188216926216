import EyeSlashSvg from 'assets/svgs/EyeSlashSvg'
import EyeSvg from 'assets/svgs/EyeSvg'
import React, { useState } from 'react'

const PasswordInput = ({ 
    label  , placeholder = '' , data = '' , setData='' , name , ...props
}) => {
    const [showPassword , setShowPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleChange = (e) => {
        const { name , value } = e.target;
        setData(prev => ({...prev , [name] : value }))
    }

    return (
        <div className='flex flex-col gap-1.5 flex-1 w-full'>
            <label className='font-semibold text-white '>
                {label}
            </label>
            <div className='relative'>
                <input 
                type={showPassword ? 'text' : 'password'}
                placeholder={placeholder}
                className='input w-full'
                name={name}
                value={data[name]}
                onChange={handleChange}
                {...props}
                />
                <div className='absolute top-1/2 sm:right-3 right-2 -translate-y-1/2 text-xl w-fit cursor-pointer text-gray-500'
                onClick={toggleShowPassword}
                >
                    {
                        showPassword ? <EyeSlashSvg /> : <EyeSvg />
                    }
                </div>
            </div>
        </div>
    )
}

export default PasswordInput