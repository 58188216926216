import Img from 'assets/images/img2.jpg';
import AngleDownSvg from 'assets/svgs/AngleDownSvg';
import AngleTopSvg from 'assets/svgs/AngleTopSvg';
import LinkOpenSvg from 'assets/svgs/LinkOpenSvg';
import SharePopup from 'components/popups/SharePopup';
import { baseURL } from 'config/api';
import moment from 'moment';
import { useState } from 'react';
import Accordion from "react-accordion-comp";
import { useDispatch, useSelector } from 'react-redux';
import { setCompaign, setShowSharePopup } from 'redux/reducers/compaignReducer';


const Compaign = ({ compaign }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { showSharePopup } = useSelector(state => state.compaign);

    return (
        <div className="bg-pure rounded-lg border border-gray-200 ">
            <div className='flex sm:items-center sm:flex-row flex-col sm:gap-0 gap-4 justify-between p-4 border-b'>
                <div className='flex sm:items-center gap-4 sm:flex-row flex-col'>
                    <div>
                        <img 
                        src={compaign?.images?.length > 0 ? baseURL+compaign?.images[0] : Img} 
                        alt="Compaign Cover" 
                        className='lg:w-[90px] lg:h-[90px] md:w-[80px] md:h-[80px] w-[60px] h-[60px] object-cover rounded-md'
                        />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <h4 className='text-sm font-semibold break-words text-white'>
                            {compaign?.name}
                        </h4>
                        <a 
                        href={compaign?.compaignUrl}
                        target='_blank'
                        className='flex items-center gap-0.5 text-sm font-medium text-gray-400'
                        >
                            <span>{compaign?.compaignUrl?.split('//')[1]} - Global</span>
                            <LinkOpenSvg />
                        </a>
                        <div className='text-primary text-xs flex items-center gap-2 '>
                            <p className='bg-primary text-white py-1 px-2 rounded-full text-[11px]'>
                                New Compaign
                            </p>
                            {/* <p className='font-medium'>
                                Up to 3.5%
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <p className='text-xs text-gray-400 text-center'>
                        {moment(compaign?.startDate).format('DD MMM')} - {moment(compaign?.endDate).format('DD MMM')}
                    </p>
                    <button 
                    className="btn-primary py-2 px- text-white"
                    onClick={() => {
                        dispatch(setCompaign(compaign));
                        dispatch(setShowSharePopup(true));
                    }}
                    >
                        Flip Share
                    </button>
                    <a 
                    href={compaign?.compaignUrl}
                    target='_blank'
                    className='text-xs text-grayText text-center'
                    >
                        Preview Site
                    </a>
                </div>
            </div>
            <div className="w-full">
                <Accordion isOpen={show} className='w-full'>
                    <div 
                    className='py-4 sm:px-4 px-2 text-sm text-grayText
                    flex flex-col gap-4 w-full'
                    >
                        <div>
                            <h6>Compaign Duration</h6>
                            <p className='font-semibold text-white mt-1'>
                                {moment(compaign?.startDate).format('DD MMM YYYY')} - {moment(compaign?.endDate).format('DD MMM YYYY')}
                            </p>
                        </div>
                        <div>
                            <h6>Compaign Url</h6>
                            <p className='w-full font-semibold text-white mt-1 break-words'>
                                {`${window.location.origin}/compaign/${compaign?._id}`}
                            </p>
                        </div>
                        <div>
                            <h6 className='font-semibold'>Description</h6>
                            <p className='mt-1 text-white'>
                                {compaign?.description}
                            </p>
                            {/* <div className='font-semibold mt-2 text-green-500 cursor-pointer'>
                                View More 
                            </div> */}
                        </div>
                        <div className='mt-6 grid lg:grid-cols-5 md:grid-cols-4 grid-cols-3 sm:gap-4 gap-2'>
                            {
                                compaign?.images?.length > 0 && compaign?.images?.map(item => (
                                    <img 
                                    key={item}
                                    src={baseURL + item}
                                    alt='Compaign Image'
                                    className='rounded-lg object-cover'
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Accordion>
            </div>
            <div
            onClick={() => setShow(!show)}
            className={`cursor-pointer py-2 flex items-center justify-center font-medium text-sm text-white gap-0.5 ${show ? 'border-t' : ''} `}
            >   
                {
                    show 
                    ? 
                        <>
                            <span>Less Details</span>
                            <span>
                                <AngleTopSvg />
                            </span>
                        </>
                    : 
                        <>
                            <span>View Details</span>
                            <span>
                                <AngleDownSvg />
                            </span>
                        </>
                }
            </div>

            {
                showSharePopup && <SharePopup />
            }
        </div>
    )
}

export default Compaign