import React from 'react'
import { useSelector } from 'react-redux'

const AvailableFunds = () => {
    const { user } = useSelector(state => state.auth);

    return (
        <div>
            <h3 className='text-lg font-semibold text-gradient'>Available Funds</h3>
            <div className='bg-pure rounded-lg py-4 sm:px-4 px-2 flex flex-col gap-6 mt-2'>
                <h4 className='font-medium text-white'>Balance available for withdrawal</h4>
                <strong className='text-lg font-semibold text-gradient'>
                    ${user?.wallet?.totalBalance}
                </strong>
                {/* <div className='text-sm text-gray-500'>
                    <p>Withdrawn to date :</p>
                    <p className='text-primary font-medium'>$1260</p>
                </div> */}
                <div>
                    <button className="btn-secondary py-2 px-4 w-full">
                        Withdraw
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AvailableFunds