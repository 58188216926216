import BannerLgImg from 'assets/images/banner-lg.png';
import BannerSmImg from 'assets/images/banner-sm.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './styles.css';
import { baseURL } from 'config/api';

const Banners = ({ banners }) => {
    return (
        <div className='w-full flex gap-2'>
            <div className='sm:flex-[0.7] flex-1'>   
                <Carousel
                showThumbs={false}
                autoPlay={true}
                infiniteLoop={true}
                swipeable={true}
                showStatus={false}
                emulateTouch={true}
                stopOnHover={false}
                className='banner-slider'
                >
                    {
                        banners?.filter(b => b.type === 'front')?.map(item => (
                            <img 
                            src={`${baseURL}${item?.image}`} 
                            alt="banner" 
                            className='w-full h-full rounded-md min-h-[150px] sm:max-h-[300px] object-cover'
                            key={item?._id}
                            />
                        ))
                    }
                </Carousel>
            </div>
            <div className='flex-[0.3] hidden sm:flex flex-col gap-2'>
                {
                    banners?.filter(b => b.type === 'side')?.map(item => (
                        <div key={item?._id}>
                            <img 
                            src={`${baseURL}${item?.image}`} 
                            alt="Small Banner" 
                            className='w-full h-[146px] rounded-md'
                            />
                        </div>
                    ))
                }
               
            </div>
        </div>
    )
}

export default Banners